import isEmail from 'validator/lib/isEmail';

/**
 * Validate human name
 * @param {string} name
 */
const libphonenumber = import('libphonenumber-js');

export const validateString = (str: any): boolean =>
  !(typeof str !== 'string' || !str || str.trim() === '');

export const validateName = (name: any): boolean => {
  if (!validateString(name)) {
    return false;
  }
  const size = name.length;
  return size > 0 && size <= 50;
};

export const validateEmail = (email: any): boolean => {
  if (!validateString(email)) {
    return false;
  }

  return isEmail(email.trim());
};

export const validatePhone = async (phone: any): Promise<boolean> => {
  if (!validateString(phone)) {
    return false;
  }

  const { parsePhoneNumberFromString } = await libphonenumber;

  const phoneParse = parsePhoneNumberFromString(phone);
  if (!phoneParse) {
    return false;
  }

  return phoneParse.isValid();
};

/**
 * @deprecated use validateString instead
 */
export const validateGiftCode = validateString;

export const validateNumber = (num: any) => {
  num = parseInt(num);
  return !(!num || num === 0 || isNaN(num));
};
