import dateFormat from 'dateformat';

/**
 * Date time typings
 */
export interface TimeLiteral {
  /**
   * Return formatted date
   * @param format
   */
  format(format: string): string;

  /**
   * Increase date by second
   * @param sec
   */
  addSeconds(sec: number): TimeLiteral;

  /**
   * Return true if equal
   * @param param/Users/im_johnder/Space/earlybird/dev/table-public/resources/frontend/src/Components/Elements/Slider
   */
  equal(param: TimeLiteral): boolean;

  /**
   * Return true if greater than
   * @param param
   */
  gt(param: TimeLiteral): boolean;

  /**
   * Return true if greater than or equal
   * @param param
   */
  gte(param: TimeLiteral): boolean;

  /**
   * Return true if less than
   * @param param
   */
  lt(param: TimeLiteral): boolean;

  /**
   * Return true if less than or equal
   * @param param
   */
  lte(param: TimeLiteral): boolean;

  /**
   * return integer version
   */

  // toNumber(): number

  /**
   * return string version
   */
  toString(): string;

  /**
   * Return true if invalid
   */
  isValid(): boolean;

  /**
   * Return numeric time
   */
  getTime(): number;
}

export type TimeInterval = number;

export interface TimeRangeLiteral {
  start(): TimeLiteral;

  end(): TimeLiteral;

  all(): string[];
}

/**
 * We create this because we don't want a large package like momentjs
 * This class is for handling time format
 * it uses dateFormat package for formatting https://github.com/felixge/node-dateformat
 * it only accept Date or string in ISO format sample: "13:30:00" or build in Date object
 */
const currentIso = dateFormat(new Date(), 'isoDate');

export class TimeUtil implements TimeLiteral {
  static currentDate: Date = new Date();
  static currentIsoDate: string = currentIso;
  /**
   * The original parameter that converted to date
   * this will we use in comparing methids like less than or equal
   */
  private date: Date;

  /**
   * Hold the original parameter pass to construct
   */
  private original: string | Date;

  /**
   * Cache int conversion of time
   */
  private timeInt: number | null;

  /**
   * @param {string} time ISO 8601 format
   */
  constructor(time: string | Date) {
    if (time instanceof Date) {
      this.date = time;
    } else {
      const parseTime: number[] = time.split(':').map(t => parseInt(t));
      this.date = new Date(TimeUtil.currentIsoDate);
      this.date.setHours(parseTime[0]);
      this.date.setMinutes(parseTime[1], parseTime[2]);
    }

    this.original = time;
    this.timeInt = null;
  }

  /**
   * can only accept ISO format
   * @param date
   */
  public static setCurrentDate(date: Date | string) {
    if (!(date instanceof Date)) {
      date = new Date(date.replace(' ', 'T'));
    }

    if (!isNaN(date.getTime())) {
      TimeUtil.currentDate = date;
      TimeUtil.currentIsoDate = dateFormat(date, 'isoDate');
    }
  }

  public static parse(time: string | Date): TimeLiteral {
    return new TimeUtil(time);
  }

  format(format: string): string {
    return dateFormat(this.date, format);
  }

  public addSeconds(sec: number): TimeLiteral {
    const newTime = this.date.getTime() + sec * 1000;
    return new TimeUtil(new Date(newTime));
  }

  public toString(): string {
    return this.format('HH:MM:ss');
  }

  equal(time: TimeLiteral): boolean {
    return this.getTime() === time.getTime();
  }

  gt(time: TimeLiteral): boolean {
    return this.getTime() > time.getTime();
  }

  gte(time: TimeLiteral): boolean {
    return this.getTime() >= time.getTime();
  }

  lt(time: TimeLiteral): boolean {
    return this.getTime() < time.getTime();
  }

  lte(time: TimeLiteral): boolean {
    return this.getTime() <= time.getTime();
  }

  public isValid(): boolean {
    if (isNaN(this.date.getTime())) {
      return false;
    }

    if (typeof this.original === 'string') {
      return this.toString() === this.original;
    }

    return true;
  }

  public getTime(): number {
    return this.date.getTime();
  }
}

/**
 * Time range supports 1 day interval only
 */
export class TimeRangeUtil implements TimeRangeLiteral {
  private startTime: TimeLiteral;
  private endTime: TimeLiteral;
  private interval: TimeInterval;

  /**
   * @param startTime ISO 8601 format
   * @param interval time in seconds
   * @param endTime ISO 8601 format
   */
  constructor(
    startTime: TimeLiteral,
    interval: TimeInterval,
    endTime?: TimeLiteral,
  ) {
    this.startTime = startTime;
    this.interval = interval;
    this.initEndTime(endTime);
  }

  public *iterator(): IterableIterator<TimeLiteral> {
    let prev: TimeLiteral = this.startTime;
    yield prev;
    while (this.endTime.gt(prev)) {
      prev = prev.addSeconds(this.interval);
      if (prev.format('HHMMss') === '000000') {
        yield this.endTime;
      } else {
        yield prev;
      }
    }
  }

  public all(): string[] {
    const dates: string[] = [];
    // try {
    for (const date of this.iterator()) {
      dates.push(date.toString());
    }
    // } catch (e) {
    //   console.warn(e)
    // }

    return dates;
  }

  public start(): TimeLiteral {
    return this.startTime;
  }

  public end(): TimeLiteral {
    return this.endTime;
  }

  private initEndTime(endTime?: TimeLiteral) {
    if (endTime) {
      this.endTime = endTime;
    } else {
      this.endTime = new TimeUtil('23:59:59');
    }
  }
}
