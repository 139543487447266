import api, { ApiParams, ApiResponseTypes, DefaultApi } from './Api';
import * as schemas from './schema';
import { normalize } from 'normalizr';
import { _get } from '../utils/object-prop';
import qsa from 'dom-helpers/querySelectorAll';
import ownDoc from 'dom-helpers/ownerDocument';

// set default prefix
api.setPrefix('/api/v4/');
api.beforeRequest((api: DefaultApi) => {
  const hasToken = api.hasHeader('X-CSRF-TOKEN');
  if (hasToken) {
    return;
  }

  const find = qsa(ownDoc(), '[name="csrf-token"]');
  if (find.length === 0) {
    return;
  }

  const _token = find[0].getAttribute('content');
  if (_token) {
    api.setHeader('X-CSRF-TOKEN', _token);
  }
});

/**
 * Get product by id
 * @param productId
 * @param params
 */
export const getByProductId = ({
  productId,
  ...params
}: {
  productId: number;
  local: string;
}) => {
  return api.get(`products/${productId}`, params).then(result => {
    return normalize(_get(result, 'data', {}), schemas.product);
  });
};

/**
 * Returns latest product stocks info
 * @param productId
 * @param params
 */
export const getProductStockInfos = (
  productId: number,
  params: Record<string, any> = {},
) => {
  return api.get(`products/${productId}/stocks`, params).then(result => {
    return normalize(_get(result, 'data', []), schemas.productInfo);
  });
};

/**
 * Get product menus by ids
 * @param productId
 * @param menuIds
 * @param params
 */
export const getByProductMenuIds = ({
  productId,
  menuIds,
  local,
}: {
  productId: number;
  menuIds: string[];
  local: string;
}) => {
  const param = menuIds.map(id => `id[]=${id}`);
  return api
    .get(`products/${productId}/menus?${param.join('&')}&local=${local}`)
    .then(result => {
      return normalize(_get(result, 'data', {}), schemas.menuInfos);
    });
};

export const getProductSeats = (productId: number, params: ApiParams) => {
  return api.get(`products/${productId}/stocks/seats`, params).then(result => {
    return normalize(_get(result, 'data', []), schemas.seatList);
  });
};

export const getProductTimes = (productId: number, params: ApiParams) => {
  return api.get(`products/${productId}/stocks/times`, params).then(result => {
    return normalize(_get(result, 'data', []), schemas.timeList);
  });
};

export const getProductMenus = (productId: number, params: ApiParams) => {
  return api.get(`products/${productId}/stocks/menus`, params).then(result => {
    return normalize(_get(result, 'data', []), schemas.menus);
  });
};

// booking
export const bookingCalculate = (productId: number, params: ApiParams) => {
  return api.post(`booking/${productId}/calculate`, params).then(result => {
    return result;
  });
};

export const bookingHold = (productId: number, params: ApiParams) => {
  return api.post(`booking/${productId}/hold`, params).then(result => {
    return result;
  });
};

export const bookingAddGuestInfo = (productId: number, params: ApiParams) => {
  return api.post(`booking/${productId}/guest`, params).then(result => {
    return result;
  });
};

export const bookingPayment = (productId: number, params: ApiParams) => {
  return api.post(`booking/${productId}/payment`, params).then(result => {
    return result;
  });
};

// giftcard

export const giftcardPayment = (params: ApiParams) => {
  return api.post(`giftcard/payment`, params).then(result => {
    return result;
  });
};

// subscribe

export const subscribeNews = (params: ApiParams) => {
  return api.post('newsletter', params).then(result => {
    return result;
  });
};

// product partners

export const getPartners = <T>(
  params: ApiParams,
): Promise<ApiResponseTypes<T>> => {
  return api.get('products', params).then(result => {
    return result;
  });
};

// blog

export const getBlogs = <T>(
  params: ApiParams,
): Promise<ApiResponseTypes<T>> => {
  return api.get('blogs', params).then(result => {
    return result;
  });
};

/**
 * Page boxes
 */

export const getPageBoxes = <T>(
  params: ApiParams,
): Promise<ApiResponseTypes<T>> => {
  return api.get('page-boxes', params).then(result => {
    return result;
  });
};

export const getTags = <T>(): Promise<ApiResponseTypes<T>> => {
  return api.get('tags').then(result => {
    return result;
  });
};
