// import {ProductActionTypes, IProductState} from "../types";
import { combineReducers } from 'redux';
import loading from './loading';
import entities from './products';
import productInfos from './productInfos';
import viewInfo from './viewInfo';
import activeProduct from './activeProduct';
import { iniState } from '../constants';
import { activeProductType, productTypes } from './productTypes';

export const productInitialState = iniState;

const rootReducer = combineReducers({
  entities,
  activeProduct,
  productInfos,
  loading,
  viewInfo,
  productIds: (state: number[] = []): number[] => state,
  activeProductType,
  productTypes,
  // ...productTypes
  // activeProductType: (state: ActiveProductType = iniState.activeProductType): ActiveProductType => state,
  // productTypes: (state: ProductTypes = {}): ProductTypes => state,
});

export default rootReducer;

export type FeatureProductState = ReturnType<typeof rootReducer>;
