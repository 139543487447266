// import './sentry'
import './polyfills';

import { render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App, { stores } from './App';
import './styles/index.scss';

// Main app
const Root = App;
const $el = document.getElementById('eb-app');

if ($el) {
  render(<Root />, $el);
}

//load plugins
try {
  import('./plugins/index').then(({ default: plugins }) => {
    plugins(stores);
  });
} catch (e) {
  console.error(e);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
