interface ApiResponseLike<T> extends Response {
  json(): Promise<T>;
}

export type ApiErrorResponse = { error: boolean; message: string };
export type ApiPaginatedResponse<T> = {
  per_page: number;
  current_page: number;
  total_data: number;
  next_page: number | null;
  data: T[];
};
export type ApiSuccessResponse = { data: string | object | Array<any> };
export type ApiResponseTypes<T = any> = ApiErrorResponse &
  (ApiSuccessResponse | ApiPaginatedResponse<T>);
export type ApiResponse = ApiResponseLike<ApiResponseTypes>;
export type ApiParams = Record<string, any> | undefined;

interface Api {
  /**
   * Get method
   * @param route
   * @param params
   */
  get(route: string, params?: ApiParams): Promise<ApiResponseTypes>;

  /**
   * Post method
   * @param route
   * @param params
   */
  post(route: string, params: ApiParams): Promise<ApiResponseTypes>;

  /**
   * Delete method
   * @param route
   * @param params
   */
  delete(route: string, params: ApiParams): Promise<ApiResponseTypes>;
}

/**
 * https://github.com/developit/unfetch#user-content-caveats
 * The Promise returned from fetch() won't reject on HTTP error status
 * This function will handle the rejection of the request
 */
export class ApiHttpErrorException extends Error {
  readonly response: ApiResponse;
  readonly code: number = 1;

  constructor(res: ApiResponse) {
    super(res.statusText);
    this.response = res;
  }
}

// implement ApiHttpErrorException
export const ApiHttpStatusErrorChecker = (res: ApiResponse) => {
  return res.ok ? res : Promise.reject(new ApiHttpErrorException(res));
};

/**
 * handles the checking after the data is parsed to jason,
 * earlybird api return "error" field which is false when fails
 */
class ApiErrorException extends Error {
  readonly response: ApiResponseTypes;
  readonly code: number = 2;

  constructor(res: ApiResponseTypes) {
    super(res.message);
    this.response = res;
  }
}

// implement ApiErrorException
const ApiErrorChecker = (res: ApiResponseTypes) => {
  return !res.error ? res : Promise.reject(new ApiErrorException(res));
};

export class DefaultApi implements Api {
  protected urlPrefix = '';
  private callback: Record<string, Function[]> = {
    before: [],
  };
  private headers: Record<string, string> = {};

  constructor(headers: Record<string, string> | undefined) {
    this.headers = headers;
  }

  public delete(route: string, params: ApiParams): Promise<ApiResponseTypes> {
    return this.fetch('delete', route, params);
  }

  public get(route: string, params?: ApiParams): Promise<ApiResponseTypes> {
    let searchStr = '';
    if (params) {
      searchStr = '?' + new URLSearchParams(params).toString();
    }
    return this.fetch('get', route + searchStr, undefined);
  }

  public post(route: string, params: ApiParams): Promise<ApiResponseTypes> {
    return this.fetch('post', route, params);
  }

  public setPrefix(prefix: string) {
    this.urlPrefix = prefix;
  }

  public setHeader = (name: string, value: string) =>
    (this.headers[name] = value);

  public hasHeader = (name: string) => this.headers[name];

  public getHeader = (name: string) => this.headers[name];

  /**
   * Hook before request is created
   * @param fn
   */
  public beforeRequest<T extends Function>(fn: T) {
    this.callback.before.push(fn);
  }

  protected fetch(
    method: string,
    route: string,
    params: ApiParams,
  ): Promise<ApiResponseTypes> {
    return fetch(this.urlPrefix + route, this.createOption(method, params))
      .then(ApiHttpStatusErrorChecker)
      .then(res => res.json())
      .then(ApiErrorChecker);
  }

  private createOption(method: string, params: ApiParams) {
    this.execCallback('before');

    method = method.toLowerCase();
    const reqInit: RequestInit = {
      method,
      headers: this.headers,
    };

    if (method === 'get' || method === 'head') {
    } else if (params) {
      reqInit.body = JSON.stringify(params);
    }

    return reqInit;
  }

  private execCallback(type: string) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const instance = this;
    this.callback[type].forEach(fn => fn(instance));
  }
}

// const _token: string = '';
export default new DefaultApi({
  'Content-Type': 'application/json',
  // 'X-CSRF-TOKEN': _token
});
