import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { actions as productActions } from './index';
import * as api from '../../../api/earlybird';
import {
  selectActiveProductInfo,
  selectActiveProductMenuInfo,
} from './reducers/productInfos';
import { AppState } from '../../reducers';
import { selectActiveProduct } from './reducers/products';
import actions from './actions';

const selectViewingInfo = (state: AppState) => state.products.viewInfo;

const getActiveProduct = function* () {
  const viewInfo = yield select(selectViewingInfo);
  const isViewingGallery =
    window?.location?.hash && window?.location?.hash === '#galleri';

  if (!viewInfo && !isViewingGallery) {
    return false;
  }

  return yield select(selectActiveProduct);
};

const taskFetchActiveProductInfo = function* () {
  const productInfo = yield select(selectActiveProductInfo);
  if (productInfo) {
    return false;
  }

  const product = yield call(getActiveProduct);
  if (!product) {
    return;
  }
  try {
    const local = yield select((state: AppState) => state.local);
    const productResult = yield call(api.getByProductId, {
      productId: product.id,
      local,
    });
    yield put(productActions.addProductInfo(productResult));
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(e);
    }
  }
};

const taskFetchActiveProductMenuInfo = function* () {
  const product = yield getActiveProduct();
  if (!product) {
    return;
  }

  const productInfoMenu = yield select(selectActiveProductMenuInfo);
  const shouldGetMenuInfo =
    productInfoMenu.length === 0 ||
    (product.menus.length !== 0 &&
      product.menus.length !== productInfoMenu.length);

  if (!shouldGetMenuInfo) {
    return;
  }

  const menus = yield select(
    (state: AppState) => state.products.entities.menus,
  );
  const local = yield select((state: AppState) => state.local);

  const menusByParent = {};
  const productParentMenuIds = product.menus
    .map(menuId => {
      const menu = menus[menuId];
      menusByParent[menu.restaurant_menu_id] = menu;
      return menu.restaurant_menu_id;
    })
    .filter(Boolean);

  if (!productParentMenuIds || productParentMenuIds.length === 0) {
    // nothing to fetch
    return;
  }

  try {
    const latestInfo = yield call(api.getByProductMenuIds, {
      productId: product.id,
      menuIds: productParentMenuIds,
      local,
    });

    // latestInfo.result = latestInfo.result.map(parentId => {
    //   const child = menusByParent[parentId];
    //   latestInfo.entities.menus[child.id] = {
    //     ...child,
    //     ...latestInfo.entities.menus[parentId],
    //     id: child.id,
    //   };
    //
    //   return child.id;
    // });

    yield put(productActions.updateProductInfoMenus(latestInfo));
  } catch (e) {
    if (process.env.NODE_ENV !== 'production') {
      console.error(e);
    }
  }
};

/**
 * Fetch all product related info we group them here to
 * manage before and after actions while fetching in api
 */
export const taskFetchProductInfo = function* () {
  yield put(productActions.loading(true));

  // register all api related info
  yield all([taskFetchActiveProductInfo(), taskFetchActiveProductMenuInfo()]);

  yield put(productActions.loading(false));
};

export const taskFetchProductInfoOnGalleryView = function* ({
  payload,
}: {
  payload: Record<string, any>;
}) {
  if (payload.location.hash === '#galleri') {
    yield taskFetchProductInfo();
  }
};

// register watchers
export default function* productWatchers() {
  yield all([
    takeLatest(actions.viewInfo.type, taskFetchProductInfo),
    // @ts-ignore
    takeLatest('@@router/LOCATION_CHANGE', taskFetchProductInfoOnGalleryView),
  ]);
}
