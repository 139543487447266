import {
  ActionNotificationClose,
  ActionNotificationShow,
  DeviceType,
  NotificationClickCallback,
  NotificationPayload,
  SharedActionTypes,
} from './types';
import {
  ACTION_TYPES,
  CHANGE_DEVICE_TYPE,
  CHANGE_VIEW_TYPE,
} from './constants';

export type NotifyConfirm = (
  callback: NotificationClickCallback,
  message?: string,
  title?: string,
) => ActionNotificationShow;

export default {
  changeView: (type: string): SharedActionTypes => ({
    type: CHANGE_VIEW_TYPE,
    payload: type,
  }),
  changeDeviceType: (device: DeviceType): SharedActionTypes => ({
    type: CHANGE_DEVICE_TYPE,
    payload: device,
  }),
  notifyOpen: (payload: NotificationPayload): ActionNotificationShow => ({
    type: ACTION_TYPES.NOTIFICATION_SHOW,
    payload,
  }),
  notifyClose: (): ActionNotificationClose => ({
    type: ACTION_TYPES.NOTIFICATION_CLOSE,
  }),
  notifyAlert: (message: string): ActionNotificationShow => {
    return {
      type: ACTION_TYPES.NOTIFICATION_SHOW,
      payload: {
        id: 'alert-modal',
        title: message,
        buttons: [
          {
            label: 'buttons.ok',
            className: 'is-primary is-small is-padded is-fullwidth',
          },
        ],
      },
    };
  },
  notifyError: (message: string): ActionNotificationShow => {
    return {
      type: ACTION_TYPES.NOTIFICATION_SHOW,
      payload: {
        id: 'alert-modal',
        title: 'errors.modal-title',
        message,
        buttons: [
          {
            label: 'buttons.ok',
            className: 'is-primary is-small is-padded is-fullwidth',
          },
        ],
      },
    };
  },
  notifyConfirm: (
    callback: NotificationClickCallback,
    message = '',
    title = '',
  ): ActionNotificationShow => ({
    type: ACTION_TYPES.NOTIFICATION_SHOW,
    payload: {
      id: 'confirm-modal',
      message: message || '',
      title: title || '',
      buttons: [
        {
          label: 'buttons.no',
          className: 'is-danger is-small is-padded',
        },
        {
          label: 'buttons.yes',
          className: 'is-primary is-small is-padded',
          clickCallback: callback,
        },
      ],
    },
  }),
  notifyBox: (body: string): ActionNotificationShow => ({
    type: ACTION_TYPES.NOTIFICATION_SHOW,
    payload: {
      id: 'box-modal',
      body,
      buttons: [],
    },
  }),
};
