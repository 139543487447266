import { GiftcardStep1, GiftcardStep2, IGiftcardState } from './types';
import {
  GIFTCARD_STEP1,
  GIFTCARD_STEP2,
  GIFTCARD_STEP3,
  initialState,
} from './constants';
import { validateStep1, validateStep2 } from './validations';
import createReducer from '../../helpers/createReducer';
import actions from './actions';

const reducers = createReducer<IGiftcardState>(initialState, {
  [actions.setActiveStepIndex]: (
    state,
    { payload }: ReturnType<typeof actions.setActiveStepIndex>,
  ) => {
    state.activeStepIndex = payload;
    return state;
  },
  [actions.formFill]: (
    state,
    { payload }: ReturnType<typeof actions.formFill>,
  ) => {
    const { step, data } = payload;
    const stepData = state.form_data[step]['data'];
    Object.entries(data).forEach(([field, value]) => {
      if (stepData.hasOwnProperty(field)) {
        stepData[field] = value;
      }
    });

    return state;
  },
  [actions.formSubmit]: (
    state,
    { payload }: ReturnType<typeof actions.formSubmit>,
  ) => {
    const { step, data } = payload;

    const stepData = state.form_data[step];
    Object.entries(data).forEach(([field, value]) => {
      if (stepData.data.hasOwnProperty(field)) {
        stepData.data[field] = value;
      }
    });

    let errors = {};
    if (step === GIFTCARD_STEP1) {
      errors = validateStep1(stepData as GiftcardStep1);
    } else if (step === GIFTCARD_STEP2) {
      errors = validateStep2(stepData as GiftcardStep2);
    } else if (step === GIFTCARD_STEP3) {
    }

    stepData.complete = Object.values(errors).length === 0;
    stepData.errors = errors;
    return state;
  },
  [actions.updateOrder]: (
    state,
    { payload }: ReturnType<typeof actions.updateOrder>,
  ) => {
    Object.entries(payload).forEach(([field, value]) => {
      if (state.order.hasOwnProperty(field)) {
        state.order[field] = value;
      }
    });

    return state;
  },

  [actions.completeStep]: (
    state,
    { payload: step }: ReturnType<typeof actions.completeStep>,
  ) => {
    const stepData = state.form_data[step];

    if (stepData) {
      stepData.complete = true;
    }

    return state;
  },
});

export default reducers;
