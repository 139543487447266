const tokenParser = (str: string) => {
  const checker = /\[([^\s[\]:*)(^]*)]/;
  let matcher;
  const foundTokens: Array<string[]> = [];
  if ((matcher = checker.exec(str)) !== null) {
    if (matcher && matcher.length > 1) {
      foundTokens.push([matcher[0], matcher[1]]);
    }
  }

  return foundTokens;
};

export default tokenParser;
