import { combineReducers } from 'redux';
import { History } from 'history';

import { reducers as sharedReducers } from './features/shared';
import { reducers as productReducers } from './features/products';
import { reducers as filterReducers } from './features/filters';
import { reducers as langReducers } from './features/languages';
import { reducers as bookingReducers } from './features/booking';
import { reducers as giftcardReducers } from './features/giftcard';
import { reducers as partnersReducers } from './features/partners';
import { reducers as blogsReducers } from './features/blogs';
import { reducers as boxesReducers } from './features/boxes';

import { IArea } from './features/filters/types';
import { connectRouter } from 'connected-react-router';
import { TimeUtil } from '../utils/date';
import { createSelector } from 'reselect';

export const createRootReducer = (history: History) => {
  return combineReducers({
    ...sharedReducers,
    ...productReducers,
    ...filterReducers,
    ...langReducers,
    ...bookingReducers,
    ...giftcardReducers,
    ...partnersReducers,
    ...blogsReducers,
    ...boxesReducers,
    activeParentCityId: (state = 0): number => state as number,
    parentAreas: (state: IArea[] = []) => state,
    date_time: (state: string = new TimeUtil(new Date()).toString()) => state,
    payment_failed: (state = false) => state,
    router: connectRouter(history),
  });
};

export type AppState = ReturnType<ReturnType<typeof createRootReducer>>;

export const selectDeviceType = createSelector(
  [(state: AppState) => state.deviceType],
  type => type,
);
