// register watchers
import actions from './actions';
import { takeLatest, select, put } from 'redux-saga/effects';
import { AppState } from '../../reducers';

function* taskGroupTagsByCategory({
  payload,
}: ReturnType<typeof actions.selectTags>) {
  const result = yield select((state: AppState) => state.categories);

  const byCategory: Record<number, number[]> = {};
  result.allIds.forEach(catId => {
    const category = result.entities.categories[catId];
    if (!category) {
      return;
    }

    category.tags.forEach(tagId => {
      if (payload.indexOf(tagId) === -1) return;

      if (!byCategory.hasOwnProperty(catId)) {
        byCategory[catId] = [];
      }

      byCategory[catId].push(tagId);
    });
  });

  yield put(actions.updateTagsByCategory(byCategory));
}

export default function* () {
  yield takeLatest(actions.selectTags.type, taskGroupTagsByCategory);
}
