import productReducers from './reducers';
import categoriesReducers from '../filters/categories/reducers';
import sagas from './sagas';
import actions from './actions';

const reducers = {
  products: productReducers,
  categories: categoriesReducers,
};

// register feature
export { sagas, reducers, actions };
