import { iniState, LOADING } from '../constants';
import { ILoadingPayload } from '../types';
import { LoadingType } from '../../../../index';

const { loading } = iniState;

export default (
  state: LoadingType = loading,
  action: ILoadingPayload,
): boolean => {
  if (action.type === LOADING) {
    return action.payload;
  }

  return state;
};
