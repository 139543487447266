import { all, call, put, select, takeEvery } from '@redux-saga/core/effects';
import { GIFTCARD_STEP1, GIFTCARD_STEP2, GIFTCARD_STEP3 } from './constants';
import { AppState } from '../../reducers';
import actions from './actions';
import sharedActions from '../shared/actions';
import { createMatchSelector, push } from 'connected-react-router';
import { selectStepData } from '../shared/reducers';
import { validateStep1 } from './validations';
import { selectTranslator } from '../languages/trans';
import { CheckoutLineItems } from '../shared/types';
import { giftcardPayment } from '../../../api/earlybird';
import { BOOKING_STEP1 } from '../booking/constants';

function* taskUpdateUrlOnMoveToNextStep() {
  const stepIndex = yield select(
    (state: AppState) => state.giftcard.activeStepIndex,
  );
  const stepInfo = yield select((state: AppState) => state.giftcard.steps);
  const activeStepRoute = stepInfo[stepIndex]?.route;
  if (!activeStepRoute) {
    return;
  }

  // TODO: validate previous steps are complete before
  const trans = yield select(selectTranslator);
  const matchSelector = createMatchSelector(trans('routes.giftcard'));
  const match = yield select(matchSelector);
  const route = `/presentkort/${activeStepRoute}`;

  if (match && route !== match.url) {
    yield put(push(route));
  }
}

function* taskFormSubmit({ payload }: ReturnType<typeof actions.formSubmit>) {
  const stepData = yield select(selectStepData, 'giftcard', payload.step);
  if (stepData.errors.hasOwnProperty('is_accept_terms')) {
    const trans = yield select(selectTranslator);
    yield put(
      sharedActions.notifyAlert(trans(stepData.errors.is_accept_terms)),
    );
    return;
  }

  if (!stepData.complete) {
    // NOTE: maybe we can move the validation here and set the step to complete
    return;
  }

  yield put(actions.setActiveStepIndex(payload.step + 1));
}

function* taskGiftcardPayment({
  payload,
}: ReturnType<typeof actions.formSubmit>) {
  const step1Data = yield select(selectStepData, 'giftcard', GIFTCARD_STEP1);
  const step2Data = yield select(selectStepData, 'giftcard', GIFTCARD_STEP2);
  if (
    !step1Data.complete ||
    !step2Data.complete ||
    payload.step !== GIFTCARD_STEP2
  ) {
    // NOTE: maybe we can move the validation here and set the step to complete
    return;
  }

  try {
    const {
      data: { amount, quantity },
    } = step1Data;
    const {
      data: { name, email, is_accept_terms, is_subscribe },
    } = step2Data;
    const params = {
      is_accept_terms,
      is_subscribe,
      name,
      email,
      amount,
      quantity,
    };

    const result = yield call(giftcardPayment, params);
    yield put(actions.completeStep(GIFTCARD_STEP3));
    yield put(actions.updateOrder({ payment_url: result.payment_url }));
  } catch (e) {
    yield put(sharedActions.notifyError('Failed, Please reload and try again'));
    yield put(actions.setActiveStepIndex(BOOKING_STEP1));

    console.error(e);
  }
}

function* taskCalculateLineItems({
  payload,
}: ReturnType<typeof actions.formFill>) {
  const stepData = yield select(selectStepData, 'giftcard', GIFTCARD_STEP1);
  const validateStep = validateStep1(stepData);
  if (Object.values(validateStep).length > 0) {
    return;
  }

  // calculate total
  const total = stepData.data.quantity * stepData.data.amount;
  const line_items: CheckoutLineItems[] = [
    {
      amount: total,
      title: '',
      type: 'giftcard_total',
      weight: 3,
    },
    {
      amount: parseFloat((total * 0.25).toFixed(2)),
      // amount: 0,
      title: '',
      type: 'tax',
      weight: 2,
    },
    {
      amount: total,
      title: '',
      type: 'payment_total',
      weight: 1,
    },
  ];

  yield put(actions.updateOrder({ total, line_items }));
}

export default function* giftcardWatchers() {
  yield all([
    takeEvery(actions.formSubmit.type, taskFormSubmit),
    takeEvery(actions.formSubmit.type, taskGiftcardPayment),
    takeEvery(actions.formFill.type, taskCalculateLineItems),
    takeEvery(actions.formSubmit.type, taskCalculateLineItems),

    takeEvery(actions.setActiveStepIndex.type, taskUpdateUrlOnMoveToNextStep),
  ]);
}
