import mainReducers from './reducers';
import { reducers as navReducers } from './nav';
import { reducers as tokensReducers } from './tokens';
import actions from './actions';

const reducers = {
  ...mainReducers,
  ...tokensReducers,
  nav: navReducers,
};

export { reducers, actions };
