import { ACTION_TYPES, iniState, SET_ACTIVE } from '../constants';
import { IActivateProductPayload, IActiveProduct } from '../types';

export default (
  state: IActiveProduct = iniState.activeProduct,
  action: IActivateProductPayload,
) => {
  switch (action.type) {
    case ACTION_TYPES.VIEWING:
    case SET_ACTIVE:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
