import { _get, ArrayOrObject } from '../../../utils/object-prop';
import { createSelector } from 'reselect';
import { AppState } from '../../reducers';

type LanguagePack = Record<string, ArrayOrObject | string>;

export type TransFnParams = Record<string, any>;
export type TransFn = (
  key: string | undefined,
  params?: TransFnParams,
  fallback?: string,
  local?: string,
) => string;

export const selectTranslator = createSelector(
  [(state: AppState) => state.languages, (state: AppState) => state.local],
  (activeLanguagesPack: LanguagePack, defaultLocal: string): TransFn => {
    return (
      key: string,
      params?: TransFnParams,
      fallback?: string,
      local: string = defaultLocal,
    ) => {
      if (!fallback) {
        fallback = key;
      }

      let txtValue = _get(activeLanguagesPack, `${local}.${key}`) as string;
      if (!txtValue) {
        // console.warn(`translation for ${key} not found`);
        return fallback;
      }

      if (params) {
        Object.entries(params).forEach(([attr, value]) => {
          txtValue = txtValue.replace(`:${attr}`, value);
        });
      }

      return txtValue;
    };
  },
);
