// Imports: Dependencies
import { all } from 'redux-saga/effects';

import { sagas as productSagas } from './features/products';
import { sagas as filterSagas } from './features/filters';
import { sagas as bookingSagas } from './features/booking';
import { sagas as giftcardSagas } from './features/giftcard';
import { sagas as partnersSagas } from './features/partners';
import { sagas as blogsSagas } from './features/blogs';
import { sagas as boxesSagas } from './features/boxes';

export function* rootSaga() {
  yield all([
    productSagas(),
    filterSagas(),
    bookingSagas(),
    giftcardSagas(),
    partnersSagas(),
    blogsSagas(),
    boxesSagas(),
  ]);
}
