import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { selectTranslator } from '../../languages/trans';
import { BOOKING_STEP1, BOOKING_STEP2, BOOKING_STEP3 } from '../constants';
import { selectBookingStepData } from '../reducers';
import actions from '../actions';
import sharedActions from '../../shared/actions';
import { AppState } from '../../../reducers';
import { selectActiveProduct } from '../../products/reducers/products';
import { bookingPayment } from '../../../../api/earlybird';

function* taskFormSubmit(action: ReturnType<typeof actions.formSubmit>) {
  if (action.payload.step !== BOOKING_STEP3) {
    return;
  }

  const stepData = yield select(selectBookingStepData, BOOKING_STEP2);
  const step1Data = yield select(selectBookingStepData, BOOKING_STEP1);
  if (!stepData.complete || !step1Data.complete) {
    // NOTE: maybe we can move the validation here and set the step to complete
    return;
  }

  yield put(actions.loading(true));
  const lang: string = yield select((state: AppState) => state.local);
  const order = yield select((state: AppState) => state.booking.order);
  const product = yield select(selectActiveProduct);
  const trans = yield select(selectTranslator);
  try {
    yield put(actions.moveToNextStep());
    const result = yield call(bookingPayment, product.id, {
      hold_id: order.hold_id,
      lang,
      name: stepData.data.name,
      email: stepData.data.email,
      phone: stepData.data.phone,
    });
    yield put(
      actions.orderHold({
        ...result.order,
        ...{
          hold_id: result.hold_id,
          order_id: result.order.id,
          booking_id: result.booking.id,
          guest_id: result.booking.guest_id,
          payment_url: result.payment_link,
        },
      }),
    );
  } catch (e) {
    yield put(sharedActions.notifyError(trans('message.all_hold_warning')));
    yield put(actions.setActiveStepIndex(BOOKING_STEP2));

    console.error(e);
  }

  yield put(actions.loading(false));
}

export default [takeLatest(actions.formSubmit.type, taskFormSubmit)];
