import {
  ActiveProductType,
  IActiveProduct,
  IProductInfoEntity,
  IProductMenu,
  IUpdateProductMenu,
  ProductActionTypes,
} from './types';
import { ACTION_TYPES } from './constants';
import { LoadingType } from '../../../index';
import { NormalizedSchema } from 'normalizr';
import { ProductId } from '../shared/types';
import createAction from '../../helpers/createAction';

export default {
  viewInfo: createAction<{ id: ProductId; slug: string }>(ACTION_TYPES.VIEWING),
  toggleViewMoreInfo: createAction(
    ACTION_TYPES.TOGGLE_VIEW_INFO,
    (disabled?: boolean) => {
      return {
        payload: disabled,
      };
    },
  ),

  activateProduct: createAction<IActiveProduct>(ACTION_TYPES.SET_ACTIVE),
  changeProductType: (
    productTypeId: ActiveProductType,
  ): ProductActionTypes => ({
    type: ACTION_TYPES.CHANGE_PRODUCT_TYPE,
    payload: productTypeId,
  }),
  loading: (isLoadingWhat: LoadingType): ProductActionTypes => ({
    type: ACTION_TYPES.LOADING,
    payload: isLoadingWhat,
  }),
  addProductInfo: createAction<NormalizedSchema<IProductInfoEntity, string>>(
    ACTION_TYPES.ADD_PRODUCT_INFO,
  ),
  // addProductInfo: (
  //   info: NormalizedSchema<IProductInfoEntity, string>
  // ): IAddProductInfo => ({
  //   type: ACTION_TYPES.ADD_PRODUCT_INFO,
  //   payload: info,
  // }),
  updateProductInfoMenus: createAction<
    NormalizedSchema<IProductInfoEntity, string>
  >(ACTION_TYPES.UPDATE_PRODUCT_INFO_MENUS),

  // updateProductInfoMenus: (
  //   info: NormalizedSchema<IProductInfoEntity, string>
  // ): IUpdateProductInfo => ({
  //   type: ACTION_TYPES.UPDATE_PRODUCT_INFO_MENUS,
  //   payload: info,
  // }),
  updateProductMenus: (info: {
    menus: Record<string, IProductMenu>;
  }): IUpdateProductMenu => ({
    type: ACTION_TYPES.UPDATE_PRODUCT_MENUS,
    payload: info,
  }),
};
