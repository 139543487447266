import produce, { Draft } from 'immer';
import { ActionPayload } from './createAction';

export type DraftReducer<
  S,
  A extends ActionPayload<any> = ActionPayload<any>
> = (state: Draft<S>, action: A) => S;

export type Reducer<S, A extends ActionPayload<any> = ActionPayload<any>> = (
  state: S,
  action: A,
) => S;

export type CaseReducers<S> = Record<string, DraftReducer<S>>;

function createReducer<State>(
  initialState: State,
  reducerCases: CaseReducers<State> = {},
): Reducer<State> {
  return (state = initialState, action): State => {
    // @ts-ignore typescript cant recognize draft state type
    return produce(state, (draft: Draft<State>) => {
      const reducer = reducerCases[action.type];
      if (action && reducer) {
        return reducer(draft, action);
      }
      return draft;
    });
  };
}

export default createReducer;
