import * as dotProp from 'dot-prop-immutable';
import { DeepPartial } from 'redux';
import deepMerge from 'deepmerge';

export type Path = number | string | Array<number | string>;

/**
 * Object/Array helpers
 * if possible use tiniest immutable library or create your own
 * and duplicate the method here for easy replacement
 */
export type ArrayOrObject<T = any> = T[] | object | Record<string, T>;

export const _get = <T = any>(
  obj: ArrayOrObject<T>,
  path: Path,
  defaultValue?: any,
): T => {
  return dotProp.get(obj, path, defaultValue);
};

export const set = <T extends ArrayOrObject>(
  obj: T,
  path: Path,
  value: unknown,
): T => {
  return dotProp.set(obj, path, value);
};

export const merge = <T extends ArrayOrObject>(
  obj: T,
  path: Path,
  value: ArrayOrObject,
): T => {
  return dotProp.merge(obj, path, value);
};

export const mergeDeep = <T extends ArrayOrObject>(
  obj1: T,
  obj12: DeepPartial<T>,
): T => {
  // @ts-ignore
  return deepMerge(obj1, obj12);
};

export const remove = <T extends ArrayOrObject>(
  obj: T,
  path: Path,
): Partial<T> => {
  return dotProp.delete(obj, path);
};
