import { ActiveFiltersType, FilterSortTypes } from './types';
import { ACTION_TYPES } from './constants';
import createAction from '../../helpers/createAction';

export default {
  resetAll: createAction(
    ACTION_TYPES.RESET,
    (filters?: Partial<ActiveFiltersType>) => {
      return { payload: filters };
    },
  ),
  selectSort: createAction<FilterSortTypes>(ACTION_TYPES.SELECT_SORT),
  selectPerson: createAction<number>(ACTION_TYPES.SELECT_PERSON),
  selectAreas: createAction<number[]>(ACTION_TYPES.SELECT_AREAS),
  selectTime: createAction<string[]>(ACTION_TYPES.SELECT_TIME),
  toggleTag: createAction<number>(ACTION_TYPES.TOGGLE_TAG),
  selectTags: createAction<number[]>(ACTION_TYPES.SET_SELECTED_TAG),
  /**
   * Update active filter tagsByCategory value
   * @param {Object<number, number[]>} payload
   */
  updateTagsByCategory: createAction<Record<number, number[]>>(
    ACTION_TYPES.TAGS_BY_CATEGORY_UPDATED,
  ),
};
