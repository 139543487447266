import { _get, set } from '../../../utils/object-prop';
import {
  ActionNotificationShow,
  DeviceType,
  IFilterOption,
  INotificationsState,
  IViewTypeState,
  NotificationActionTypes,
  SharedActionTypes,
  ViewTypes,
} from './types';
import {
  ACTION_TYPES,
  CHANGE_DEVICE_TYPE,
  CHANGE_VIEW_TYPE,
} from './constants';
import { defaultNotificationState, defaultViewState } from './defaultstates';
import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import {
  selectActiveParentCity,
  selectActiveProductType,
  selectLocal,
} from '../products/reducers/productTypes';
import { selectTranslator } from '../languages/trans';
import { BookingSteps } from '../booking/types';
import { GiftcardSteps } from '../giftcard/types';

export const viewTypes = (
  state: IViewTypeState = defaultViewState,
  action: SharedActionTypes,
): IViewTypeState => {
  switch (action.type) {
    case CHANGE_VIEW_TYPE:
      return set(state, 'activeViewType', action.payload);
    default:
      return state;
  }
};

export const deviceType = (
  state: DeviceType = 'desktop',
  action: SharedActionTypes,
): DeviceType => {
  switch (action.type) {
    case CHANGE_DEVICE_TYPE:
      return action.payload;
    default:
      return state;
  }
};

export const notification = (
  state: INotificationsState = defaultNotificationState,
  action: NotificationActionTypes,
): INotificationsState => {
  switch (action.type) {
    case ACTION_TYPES.NOTIFICATION_SHOW: {
      const { payload } = action as ActionNotificationShow;
      return {
        ...state,
        ...payload,
        open: true,
      };
    }
    case ACTION_TYPES.NOTIFICATION_CLOSE: {
      return set(state, 'open', false);
    }
    default:
      return state;
  }
};

export default {
  viewTypes,
  deviceType,
  notification,
};

export const selectViewTypeFilterOption = createSelector(
  [
    (state: AppState) => state.viewTypes.entities.viewTypes,
    selectLocal,
    selectActiveProductType,
    selectTranslator,
    selectActiveParentCity,
  ],
  (
    viewTypes: ViewTypes,
    local: string,
    productType: IFilterOption,
    trans,
    parentCity,
  ): IFilterOption[] => {
    if (!parentCity) {
      return [];
    }
    return Object.values(viewTypes).map(type => {
      const by = type.id === 'map' ? '_map' : '';
      return {
        ...type,
        label: trans(type.label),
        route: trans(`routes.${productType.id}_by_city${by}`, {
          city: parentCity.slug,
        }),
      };
    });
  },
);

export const selectStepData = createSelector(
  [
    (state: AppState, featureKey: string, step: number) => step,
    (state: AppState, featureKey: string, step: number) =>
      _get(state, `${featureKey}.form_data`) as
        | Record<number, BookingSteps>
        | Record<number, GiftcardSteps>,
  ],
  (
    activeStep: number,
    formData: Record<number, BookingSteps> | Record<number, GiftcardSteps>,
  ) => {
    return _get(formData, activeStep, {});
  },
);

export const makeSelectStepData = <T = any>() =>
  createSelector(
    [
      (state: AppState, featureKey: string, step: number) => step,
      (state: AppState, featureKey: string, step: number) =>
        _get(state, `${featureKey}.form_data`) as Record<number, T>,
    ],
    (activeStep: number, formData: Record<number, T>) => {
      return _get(formData, activeStep, {});
    },
  );
