import {
  applyMiddleware,
  compose,
  createStore,
  DeepPartial,
  Store,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import history from './history';

import { rootSaga } from './sagas';
import { AppState, createRootReducer } from './reducers';

export { history };

export const stores = (preloadState?: DeepPartial<AppState>): Store => {
  const sagaMiddleware = createSagaMiddleware();

  const composeEnhancers: typeof compose =
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const middleware = [routerMiddleware(history), sagaMiddleware];

  // TODO: re-enable when createReducer helper is widely used
  // if (process.env.NODE_ENV !== 'production') {
  //   middleware.push(require('redux-immutable-state-invariant').default());
  // }

  const stores = createStore(
    createRootReducer(history),
    // @ts-ignore stop comparing to DeepPartial<AppState>
    preloadState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  // @ts-ignore
  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // @ts-ignore
    module.hot.accept('./reducers', () => {
      stores.replaceReducer(
        createStore(
          createRootReducer(history),
          // @ts-ignore stop comparing to DeepPartial<AppState>
          preloadState,
          composeEnhancers(applyMiddleware(...middleware)),
        ),
      );
    });
  }

  return stores;
};

export default stores;
