import { closest, listen } from 'dom-helpers';
import qsa from 'dom-helpers/querySelectorAll';
import { useEffect, useRef } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import {
  BOOKING_STEP1,
  BOOKING_STEP2,
  BOOKING_STEP3,
  BOOKING_STEP4,
} from '../core/redux/features/booking/constants';
import {
  BookingStep1Data,
  BookingStep2,
  BookingStep2Data,
  BookingStep4,
} from '../core/redux/features/booking/types';
import {
  GIFTCARD_STEP1,
  GIFTCARD_STEP2,
  GIFTCARD_STEP3,
} from '../core/redux/features/giftcard/constants';
import {
  GiftcardStep1,
  GiftcardStep2,
  GiftcardStep3,
} from '../core/redux/features/giftcard/types';
import { selectActiveProduct } from '../core/redux/features/products/reducers/products';
import { AppState } from '../core/redux/reducers';
import crypto from 'crypto-js/sha1';
import { selectFilteredProductIds } from 'core/redux/features/products/reducers/products';

const clearECom = () => _dataLayer({ ecommerce: null });
const ecom = (data: Record<string, any>) => {
  clearECom();
  _dataLayer(data);
};

const _dataLayer = (args: any) => {
  // // @ts-ignore
  window.dataLayer = window.dataLayer || [];
  // @ts-ignore
  window.dataLayer.push(args);

  if (process.env.NODE_ENV !== 'production') {
    // @ts-ignore
    console.log('dataLayer:', JSON.stringify(args));
  }
};

export const useDataLayerViewItemList = () => {
  const { products, productIds } = useSelector((state: AppState) => ({
    products: state.products.entities.products,
    productIds: selectFilteredProductIds(state),
  }));

  useEffect(() => {
    if (!products || !productIds) {
      return;
    }

    ecom({
      event: 'view_item_list',
      ecommerce: {
        currency: 'SEK',
        items: productIds.map(item => {
          const product = products[item.id];
          return {
            item_name: product.name,
            item_id: product.id,
            price: String(product.price_sale),
            // discount: 100,
            // item_brand: 'item_brand_1',
            item_category: product.product_type,
            // item_variant: 'item_variant_1',
            quantity: 1,
          };
        }),
      },
    });
  }, []);
};
export const useDataLayerViewProductDetails = () => {
  const activeProduct = useSelector(selectActiveProduct);

  useEffect(() => {
    if (!activeProduct) {
      return;
    }

    ecom({
      event: 'view_detail',
      ecommerce: {
        detail: {
          products: [
            {
              id: activeProduct.id,
              category: activeProduct.product_type,
              name: activeProduct.name,
              price: String(activeProduct.price_sale),
            },
          ],
        },
      },
    });

    ecom({
      event: 'view_item',

      ecommerce: {
        currency: 'SEK',
        value: String(activeProduct.price_sale),
        items: [
          {
            item_name: activeProduct.name,
            item_id: activeProduct.id,
            price: String(activeProduct.price_sale),
            // discount: 100,
            // item_brand: 'item_brand_1',
            item_category: activeProduct.product_type,
            // item_variant: 'item_variant_1',
            quantity: 1,
          },
        ],
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeProduct?.id]);
};

export const useDataLayerCheckout = () => {
  useCheckoutStep1();
  useCheckoutStep2();
  useCheckoutStep3();
  useCheckoutStep4();
};

export const useCheckoutStep4 = () => {
  const product = useSelector(selectActiveProduct);
  const { activeStep, order, stepData, step2Data } = useSelector(
    (state: AppState) => ({
      activeStep: state.booking.activeStepIndex,
      order: state.booking.order,
      stepData: state.booking.form_data[BOOKING_STEP1].data as BookingStep1Data,
      step2Data: state.booking.form_data[BOOKING_STEP2] as BookingStep2,
      step4Data: state.booking.form_data[BOOKING_STEP4] as BookingStep4,
    }),
  );

  useEffect(() => {
    if (activeStep !== BOOKING_STEP4) {
      return;
    }

    const price = order.line_items.find(i => i.type === 'total_amount')?.amount;
    const productType = product.product_type;
    const productId = product.id;

    if (!price || !productType || !productId) {
      return;
    }

    ecom({
      event: 'checkout',
      customerEmail: step2Data.data.email,
      customerName: step2Data.data.name,
      customerPhone: step2Data.data.phone,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 2,
            option: 'payment',
          },
          products: [
            {
              id: productId,
              name: product.name,
              price: String(price / stepData.num_person),
              category: productType,
              quantity: stepData.num_person,
            },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);
};

export const useCheckoutStep3 = () => {
  const product = useSelector(selectActiveProduct);
  const { activeStep, order, stepData } = useSelector((state: AppState) => ({
    activeStep: state.booking.activeStepIndex,
    order: state.booking.order,
    stepData: state.booking.form_data[BOOKING_STEP1].data as BookingStep1Data,
    // step3Data: state.booking.form_data[BOOKING_STEP3] as BookingStep3,
  }));

  useEffect(() => {
    if (activeStep !== BOOKING_STEP3) {
      return;
    }

    const price = order.line_items.find(i => i.type === 'total_amount')?.amount;
    const productType = product.product_type;
    const productId = product.id;

    if (!price || !productType || !productId) {
      return;
    }

    ecom({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 2,
            option: 'terms',
          },
          products: [
            {
              id: productId,
              name: product.name,
              price: String(price / stepData.num_person),
              category: productType,
              quantity: stepData.num_person,
            },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);
};

export const useCheckoutStep2 = () => {
  const product = useSelector(selectActiveProduct);
  const { activeStep, order, stepData, step2Data } = useSelector(
    (state: AppState) => ({
      activeStep: state.booking.activeStepIndex,
      order: state.booking.order,
      stepData: state.booking.form_data[BOOKING_STEP1].data as BookingStep1Data,
      step2Data: state.booking.form_data[BOOKING_STEP2]
        .data as BookingStep2Data,
    }),
  );

  useEffect(() => {
    if (activeStep !== BOOKING_STEP2) {
      return;
    }

    // coop_code: null
    // coop_zip: null
    // email: "..."
    // giftcodes: []
    // is_subscribe: true
    // last_giftcode: ""
    // name: "..."
    // phone: "+...."

    const price = order.line_items.find(i => i.type === 'total_amount')?.amount;
    const productType = product.product_type;
    const productId = product.id;

    if (!price || !productType || !productId) {
      return;
    }

    ecom({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 2,
            option: 'info',
          },
          products: [
            {
              id: productId,
              name: product.name,
              price: String(price / stepData.num_person),
              category: productType,
              quantity: stepData.num_person,
            },
          ],
        },
      },
    });

    ecom({
      event: 'begin_checkout',
      ecommerce: {
        currency: 'SEK',
        value: String(price),
        items: [
          {
            item_name: product.name,
            item_id: productId,
            price: String(price / stepData.num_person),
            // discount: 100,
            // item_brand: 'item_brand_1',
            item_category: productType,
            // item_variant: 'item_variant_1',
            quantity: stepData.num_person,
          },
        ],
      },
    });

    if (step2Data.is_subscribe) {
      ecom({ event: 'subscription', customerEmail: step2Data.email });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);
};

export const useCheckoutStep1 = () => {
  const product = useSelector(selectActiveProduct);
  const { activeStep } = useSelector((state: AppState) => ({
    activeStep: state.booking.activeStepIndex,
    // order: state.booking.order,
    // stepData: state.booking.form_data[BOOKING_STEP1].data as BookingStep1Data,
  }));

  useEffect(() => {
    if (activeStep !== BOOKING_STEP1) {
      return;
    }

    // const price = order.line_items.find(i => i.type === 'total_amount')?.amount;
    const productType = product.product_type;
    const productId = product.id;
    //
    // if (!price || !productType || !productId) {
    //   return;
    // }

    ecom({
      event: 'checkout',
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 1,
            option: 'book',
          },
          products: [
            {
              id: productId,
              name: product.name,
              // price: String(price / stepData.num_person),
              category: productType,
              // quantity: stepData.num_person,
              // time: stepData.time,
              // menus: Object.values(stepData.menus),
            },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);
};

export const useDataLayerPurchase = () => {
  const lastOrderId = useRef<any>();
  useEffect(() => {
    const currency = window.__pixel_data?.currency;
    const price = window.__pixel_data?.total;
    const orderId = window.__pixel_data?.order_id;
    const productType = window.__pixel_data?.product_type;
    const productId = window.__pixel_data?.restaurant_id;
    const productName = window.__pixel_data?.restaurant_name;
    const email = window.__pixel_data?.email;
    const name = window.__pixel_data?.name;
    const phone = window.__pixel_data?.phone;

    if (
      !currency ||
      !price ||
      !orderId ||
      !productType ||
      lastOrderId.current === orderId
    ) {
      return;
    }

    lastOrderId.current = orderId;

    ecom({
      event: 'purchase',
      customerEmail: email,
      customerName: name,
      customerPhone: phone,
      ecommerce: {
        currencyCode: 'SEK',
        purchase: {
          actionField: {
            id: orderId,
            affiliation: 'earlybird',
            revenue: price,
          },
          products: [
            {
              id: productId,
              name: productName,
              category: productType,
              quantity: 1,
              price: price,
            },
          ],
        },
      },
    });
  }, []);
};

function parseNetworkCookie(cookie?: string) {
  if (!cookie) return null;

  return new URLSearchParams(cookie);
}
let stopInter;
export const useSubscribeDataLayer = () => {
  let [cookies] = useCookies();

  useEffect(() => {
    let emailValue: string | undefined = undefined;
    listen(document.body, 'click', e => {
      const getButton = () =>
        closest(e.target as Element, '.klaviyo-form button');

      const klaviyoSubmit = getButton();

      const klaviyoForm = closest(e.target as Element, '.section-box');
      const email = qsa(document.body, '.klaviyo-form [name="email"]');

      if (!klaviyoSubmit || !klaviyoForm || email.length === 0) {
        return;
      }
      clearInterval(stopInter);

      emailValue = (email[0] as HTMLInputElement).value;

      async function networkFetch(emailValue: string) {
        if (!emailValue || emailValue === '') {
          return;
        }

        let networkCookie = parseNetworkCookie(cookies['ebntp']);
        let paid = networkCookie?.get('paid');
        let pacid = networkCookie?.get('pacid');
        let uniqueId = crypto(emailValue).toString();

        return await fetch(
          `https://www.partner-ads.com/dk/leadtracks2s.php?programid=4689&type=lead&partnerid=${paid}&pacid=${pacid}&uiv=${uniqueId}`,
          { method: 'GET', mode: 'no-cors' },
        );
      }

      let max = 20; // 20 sec timeout
      stopInter = setInterval(() => {
        if (max <= 0) {
          clearInterval(stopInter);
        }

        // when button is remove means success
        if (!getButton()) {
          let res = networkFetch(emailValue)
            .then(() => {})
            .catch(() => {});

          _dataLayer({
            event: 'subscription',
            customerEmail: emailValue,
          });

          clearInterval(stopInter);
        }

        max--;
      }, 1000);

      const remove = listen(klaviyoForm as HTMLElement, 'change', e => {
        console.log(
          emailValue,
          qsa(document.body, '.klaviyo-form-SJvRp7 [name="email"]'),
        );

        remove();
      });
    });
  }, []);
};

export const useGCCheckoutStep = () => {
  const { activeStep, stepData1, stepData2, stepData3 } = useSelector(
    (state: AppState) => ({
      activeStep: state.giftcard.activeStepIndex,
      // order: state.giftcard.order,
      stepData1: state.giftcard.form_data[GIFTCARD_STEP1] as GiftcardStep1,

      stepData2: state.giftcard.form_data[GIFTCARD_STEP2] as GiftcardStep2,

      stepData3: state.giftcard.form_data[GIFTCARD_STEP3] as GiftcardStep3,
    }),
  );

  //////////////// STEP 1 DONE
  useEffect(() => {
    if (activeStep !== GIFTCARD_STEP2 || !stepData1.complete) {
      return;
    }

    ecom({
      event: 'checkout',
      giftCardQuantity: stepData1.data.quantity,
      giftCardValue: stepData1.data.amount,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 1,
            option: 'giftcard',
          },
          products: [
            // {
            //   category: 'giftcard',
            //   quantity: stepData1.data.quantity,
            //   price: order.total,
            // },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, stepData1.complete]);

  //////////////// STEP 2 DONE
  useEffect(() => {
    if (activeStep === GIFTCARD_STEP2 || !stepData2.complete) {
      return;
    }

    ecom({
      event: 'checkout',
      giftCardQuantity: stepData1.data.quantity,
      giftCardValue: stepData1.data.amount,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 2,
            option: 'info',
          },
          products: [
            // {
            //   category: 'giftcard',
            //   quantity: stepData1.data.quantity,
            //   price: order.total,
            // },
          ],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, stepData2.complete]);

  //////////////// STEP 3 DONE
  useEffect(() => {
    if (activeStep !== GIFTCARD_STEP3 || !stepData3.complete) {
      return;
    }

    ecom({
      event: 'checkout',
      customerEmail: stepData2.data.email,
      customerName: stepData2.data.name,
      giftCardQuantity: stepData1.data.quantity,
      giftCardValue: stepData1.data.amount,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 3,
            option: 'payment',
          },
          products: [
            // {
            //   category: 'giftcard',
            //   quantity: stepData1.data.quantity,
            //   price: order.total,
            // },
          ],
        },
      },
    });

    ecom({
      event: 'begin_checkout',
      customerEmail: stepData2.data.email,
      customerName: stepData2.data.name,
      giftCardQuantity: stepData1.data.quantity,
      giftCardValue: stepData1.data.amount,
      ecommerce: {
        currencyCode: 'SEK',
        checkout: {
          actionField: {
            step: 3,
          },
          products: [],
        },
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep, stepData3.complete]);
};

export const useGCDataLayerPurchase = () => {
  const lastOrderId = useRef<any>();
  useEffect(() => {
    const currency = window.__pixel_data_gc?.currency;
    const price = window.__pixel_data_gc?.total;
    const orderId = window.__pixel_data_gc?.order_id;
    const email = window.__pixel_data_gc?.email;
    const name = window.__pixel_data_gc?.name;
    const products = window.__pixel_data_gc?.products;

    if (
      !currency ||
      !price ||
      !orderId ||
      lastOrderId.current === orderId ||
      !products
    ) {
      return;
    }

    lastOrderId.current = orderId;

    ecom({
      event: 'purchase',
      customerEmail: email,
      customerName: name,
      customerPhone: '',
      ecommerce: {
        currencyCode: 'SEK',
        purchase: {
          actionField: {
            id: orderId,
            affiliation: 'earlybird',
            revenue: price,
          },
          products,
        },
      },
    });
  }, []);
};
