import { normalize } from 'normalizr';
import { _get } from 'core/utils/object-prop';
import { filterInitialState } from 'core/redux/features/filters/reducers';
import { productInitialState } from 'core/redux/features/products/reducers';
import * as apiSchema from 'core/api/schema';
import { defaultViewState } from 'core/redux/features/shared/defaultstates';
import { AppState } from 'core/redux/reducers';
import { DeepPartial } from 'redux';
import qsa from 'dom-helpers/querySelectorAll';
import ownDoc from 'dom-helpers/ownerDocument';
import { boxInitialState, IBoxRecords } from 'core/redux/features/boxes/box';
import { navInitialState } from 'core/redux/features/shared/nav';
import { partnersDefaultState } from 'core/redux/features/partners';
import { blogsDefaultState } from 'core/redux/features/blogs';
import { defaultTokenState } from 'core/redux/features/shared/tokens';
import { pageBoxesDefaultState } from 'core/redux/features/boxes/pageBoxes';
import { TimeUtil } from 'core/utils/date';

const __doc = ownDoc();

type QueryDeviceType = string | 'mobile' | 'tablet' | 'desktop';
let lastDeviceType: QueryDeviceType = 'desktop';
export const getMediaQuery = (
  listener?: (type: string) => void,
): QueryDeviceType => {
  // return;
  const mediaQueries: Record<QueryDeviceType, MediaQueryList> = {
    mobile: window.matchMedia('screen and (max-width: 760px)'), // mobile
    tablet: window.matchMedia('screen and (min-width: 761px)'), // tablet
    desktop: window.matchMedia('screen and (min-width: 1024px)'), // desktop
  };

  const deviceChanger = (type, mq) => {
    if (listener && mq.matches && lastDeviceType !== type) {
      lastDeviceType = type;
      listener(type);
    }
  };

  const deviceSubscribe = type => mq => deviceChanger(type, mq);

  for (const result of Object.entries(mediaQueries)) {
    const type: QueryDeviceType = result[0];
    const mq = result[1];
    if (listener) {
      if (mq.addEventListener) {
        mq.addEventListener('change', deviceSubscribe(type));
      } else if (mq.addListener) {
        mq.addListener(deviceSubscribe(type));
      }

      if (mq.matches) {
        listener(type);
      }
    }

    if (mq.matches) {
      lastDeviceType = type;
    }
  }

  return lastDeviceType;
};

function parsePreloadState(
  __PRELOAD_STATE__ = _get(window, '__PRELOAD_STATE__'),
): DeepPartial<AppState> | undefined {
  const urlSearchValues = new URLSearchParams(window.location.search);
  if (process.env.NODE_ENV === 'production') {
    // TODO: uncomment next line before release
    delete window.__PRELOAD_STATE__;
  }

  // TODO: remove this function before release
  function LOG(...args: any) {
    if (process.env.NODE_ENV !== 'production') {
      // console.log(...args)
    }
  }

  if (!__PRELOAD_STATE__) {
    LOG('invalid state', __PRELOAD_STATE__);
    return undefined;
  }

  const activeProduct = _get(__PRELOAD_STATE__, 'activeProduct', {});
  LOG('activeProduct', activeProduct);

  const productInfo = (() => {
    const info = _get(__PRELOAD_STATE__, 'productInfo', []);
    if (!info || info.length === 0) {
      return null;
    }

    return normalize(info, apiSchema.productInfo);
  })();

  LOG('productInfo', productInfo);

  const parentCity = _get(__PRELOAD_STATE__, 'parent_city', []);
  LOG('parentCity', parentCity);

  const parentAreas = _get(__PRELOAD_STATE__, 'parent_areas', []).map(a => ({
    ...a,
    id: a.id,
    lat: a.geo_lng_value,
    lng: a.geo_long_value,
    slug: a.link,
    label: a.proper_name,
  }));

  LOG('parentAreas', parentAreas);

  const areas = _get(__PRELOAD_STATE__, 'areas', []).map((area: any) => ({
    id: area.id,
    label: area.proper_name,
    slug: area.link,
    lat: area.geo_lat_value,
    lng: area.geo_long_value,
    link: area.link,
  }));

  LOG('areas', areas);

  const activeAreas = urlSearchValues
    .getAll('areas')
    .map(areaSlug => {
      const find = areas.find(a => a.slug === areaSlug);

      if (find) {
        return find.id;
      }

      return null;
    })
    .filter(a => a !== null);
  // const activeAreas = _get(__PRELOAD_STATE__, 'activeChildArea.id', null)

  const otherCity = parentCity
    ? parentAreas.find(a => parentCity.id !== a.id)
    : null;
  // if (otherCity) {
  //   areas.push({
  //     id: otherCity.id,
  //     label: otherCity.proper_name,
  //     is_parent: true,
  //     slug: otherCity.link,
  //     lat: otherCity.geo_lng_value,
  //     lng: otherCity.geo_long_value,
  //   })
  // }

  LOG('otherCity', otherCity);

  const categories = _get(__PRELOAD_STATE__, 'categories', []);

  const normalizedCat = normalize(categories, apiSchema.categories);

  const tagsVals = Object.values(normalizedCat.entities?.tags ?? {});
  const activeCategoryTags = urlSearchValues
    .getAll('tags')
    .map(tagSlug => {
      const find = tagsVals.find(tag => {
        return tag.slug === tagSlug || tag.slug === tagSlug.replaceAll('?', '');
      });
      if (find) {
        return find.id;
      }
      return null;
    })
    .filter(a => a !== null);

  const normalizedProduct = normalize(
    _get(__PRELOAD_STATE__, 'products.data', []),
    apiSchema.products,
  );

  LOG(
    'normalizedProduct',
    normalizedProduct,
    __PRELOAD_STATE__,
    _get(__PRELOAD_STATE__, 'products.data', []),
  );

  const activeProductType = _get(__PRELOAD_STATE__, 'activeProductType', null);
  LOG('activeProductType', activeProductType);

  const local = _get(__PRELOAD_STATE__, 'local', 'da');
  LOG('local', local);

  const sortOptions = normalize(
    _get(__PRELOAD_STATE__, 'sortOptions', [{ id: '0', label: 'sort' }]),
    apiSchema.sortOptions,
  );

  LOG('sortOptions', sortOptions);

  const productInfoEntity = productInfo ? productInfo.entities : {};

  const boxEntities: IBoxRecords = {};
  const boxResults: string[] = [];

  const $allBox = qsa(__doc, '.script-box-contents');
  $allBox.forEach(function ($item) {
    const boxId: string = $item.getAttribute('id');
    if (!boxId) {
      return;
    }
    boxResults.push(boxId);
    boxEntities[boxId] = {
      id: boxId,
      content: $item.innerText,
    };
  });

  LOG('boxEntitles', boxEntities);
  const isPaymentFailed =
    window && window.location.href.indexOf('ispafa=1') > -1;
  return {
    payment_failed: isPaymentFailed,
    date_time: _get(
      __PRELOAD_STATE__,
      'data_time',
      TimeUtil.parse(new Date()).format('isoDateTime'),
    ),
    deviceType: getMediaQuery(),
    local: local,
    activeParentCityId: _get(__PRELOAD_STATE__, 'parent_city.id', ''),
    parentAreas: parentAreas,
    viewTypes: {
      ...defaultViewState,
      activeViewType: _get(__PRELOAD_STATE__, 'viewType', 'list'),
    },
    categories: {
      entities: normalizedCat.entities,
      allIds: normalizedCat.result,
    },
    products: {
      ...productInitialState,
      activeProductType: activeProductType
        ? activeProductType.id
        : 'restaurant',
      productTypes: _get(__PRELOAD_STATE__, 'productTypes', {}),
      entities: normalizedProduct.entities,
      productIds: normalizedProduct.result,
      productInfos: {
        ...productInitialState.productInfos,
        ...productInfoEntity,
      },
      viewInfo: productInfo !== null,
      activeProduct: {
        id: _get(
          activeProduct || {},
          'restaurant_id',
          normalizedProduct.result[0] || '0',
        ),
        slug: _get(activeProduct || {}, 'slug', ''),
      },
    },
    filters: {
      ...filterInitialState,
      activeFilters: {
        ...filterInitialState.activeFilters,
        areas: activeAreas,
        tags: activeCategoryTags,
      },
      areas,
      sorts: {
        entities: sortOptions.entities,
        allIds: sortOptions.result,
      },
    },
    box: {
      ...boxInitialState,
      entities: boxEntities,
      ids: boxResults,
    },
    nav: {
      ...navInitialState,
      menus: _get(__PRELOAD_STATE__, 'navMenus', navInitialState.menus),
      hideById: {
        ...navInitialState.hideById,
        ..._get(__PRELOAD_STATE__, 'navHideById', {}),
      },
    },
    partners: {
      ...partnersDefaultState,
      results: {
        all: {
          ...partnersDefaultState.results.all,
          ..._get(__PRELOAD_STATE__, 'partners', {}),
        },
      },
    },
    blogs: {
      ...blogsDefaultState,

      extra: {
        ...(blogsDefaultState?.extra ?? {}),
        tags: _get(__PRELOAD_STATE__, 'tags', []),
      },
      results: {
        all: {
          ...blogsDefaultState.results.all,
          next_page: 2,
          data: _get(__PRELOAD_STATE__, 'blogs', []),
        },
      },
    },
    tokens: {
      ...defaultTokenState,
      ..._get(__PRELOAD_STATE__, 'tokens', {}),
    },
    pageBoxes: {
      ...pageBoxesDefaultState,
      results: {
        bottom: {
          ...pageBoxesDefaultState.results.bottom,
          ..._get(__PRELOAD_STATE__, 'pageBoxes', {}),
        },
        top: {
          ...pageBoxesDefaultState.results.bottom,
          ..._get(__PRELOAD_STATE__, 'pageBoxes', {}),
        },
      },
    },
  };
}

export default parsePreloadState;
