import {
  LanguageActionTypes,
  LanguageKeyType,
  Languages,
  LanguageState,
} from './types';
import { CHANGE_LOCAL } from './constants';
import enLocals from '../../../lang/en';
import daLocals from '../../../lang/da';

export const languageInitialState: LanguageState = {
  all: {
    en: enLocals,
    da: daLocals,
  },
  active: 'da',
};

export const activeLocalReducer = (
  state: LanguageKeyType = languageInitialState.active,
  action: LanguageActionTypes,
): LanguageKeyType => {
  if (action.type === CHANGE_LOCAL) {
    return action.payload;
  }

  return state;
};

export default {
  local: activeLocalReducer,
  languages: (state: Languages = languageInitialState.all): Languages => state,
};
