import {
  BookingStep1,
  BookingStep1Data,
  BookingStep2,
  BookingStep2Data,
  PartialString,
} from './types';
import { TimeUtil } from '../../../utils/date';
import {
  validateEmail,
  validateName,
  validatePhone,
  validateString,
} from '../shared/validations';
import { call } from '@redux-saga/core/effects';

/**
 * Validate if selected menu is enough for the selected person
 * @param step
 */
export const validateMenuRecords = (step: BookingStep1) => {
  const menus = step.data.menus;
  let totalSelectedNumPerson = 0;
  Object.entries(menus).forEach(([_, { num_person }]) => {
    totalSelectedNumPerson += num_person;
  });

  return totalSelectedNumPerson === step.data.num_person;
};

export const validateNameAndEmail = (
  inputs: BookingStep2,
): PartialString<BookingStep2Data> => {
  const errors: PartialString<BookingStep2Data> = {};
  const { data } = inputs;

  if (!validateName(data.name)) {
    errors.name = 'errors.name';
    return errors;
  }

  if (!validateEmail(data.email)) {
    errors.email = 'errors.email';
    return errors;
  }

  return errors;
};

export function validateStep1(
  inputs: BookingStep1,
): PartialString<BookingStep1Data> {
  const errors: PartialString<BookingStep1Data> = {};

  const isValidPerson =
    inputs.data.num_person > 0 && inputs.data.num_person <= 8;
  if (!isValidPerson) {
    errors.num_person = 'errors.num_person';
    return errors;
  }

  const valid = validateTime(inputs.data.time);
  if (valid !== true) {
    errors.time = valid;
    return errors;
  }

  if (!validateMenuRecords(inputs)) {
    errors.menus = ''; //'errors.menus';
    return errors;
  }

  return errors;
}

export function* validateStep2(
  inputs: BookingStep2,
): Generator<any, PartialString<BookingStep2Data>> {
  const errors: PartialString<BookingStep2Data> = validateNameAndEmail(inputs);
  if (Object.values(errors).length > 0) {
    return errors;
  }

  const { data } = inputs;

  const isValidPhone = yield call(validatePhone, data.phone);
  if (!isValidPhone) {
    errors.phone = 'errors.phone';
    return errors;
  }

  return errors;
}

export async function validateGiftCodeRequirements(
  inputs: BookingStep2,
): Promise<PartialString<BookingStep2Data>> {
  const errors: PartialString<BookingStep2Data> = {};
  const { data } = inputs;
  if (
    data.giftcodes.length > 0 &&
    data.giftcodes.indexOf(data.last_giftcode) > -1
  ) {
    errors.last_giftcode = 'errors.gc-exist';
    return errors;
  }

  if (!validateEmail(data.email)) {
    errors.email = 'errors.email';
  }

  const isValidPhone = await validatePhone(data.phone);
  if (!isValidPhone) {
    errors.phone = 'errors.phone';
  }

  if (Object.values(errors).length > 0 || !validateString(data.last_giftcode)) {
    errors.last_giftcode = 'errors.gc-mail-phone';
    return errors;
  }

  return errors;
}

/**
 * Return translation key if the time is invalid
 * @param time
 */
export function validateTime(time: any) {
  const isValidTime =
    typeof time !== 'string' && time === '0'
      ? false
      : new TimeUtil(time).isValid();
  if (!isValidTime) {
    return 'errors.time';
  }

  return true;
}

export function validateCoopCode(code: any) {
  if (typeof code !== 'number') {
    return 'errors.coop_code';
  }

  // Min digits: 1
  let minLen = 1;
  // Max digits: 8
  let maxLen = 8;

  let codeStr = String(code);
  let codeLen = codeStr.length;
  if (codeLen > maxLen || codeLen < minLen) {
    return 'errors.coop_code';
  }

  // Must not be possible to enter this number (must give an error of wrong number): 43862020, 12345678

  let invalidCodes = [43862020, 12345678];

  if (invalidCodes.indexOf(code) > -1) {
    return 'errors.coop_code';
  }

  // If the number is 8 digits then it must not start with any of these numbers
  // (must give an error of wrong number): 21, 22, 23, 24, 25, 26, 27, 28, 29, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49 og op til 99.
  let invalidCodeStartWith = [
    21,
    22,
    23,
    24,
    25,
    26,
    27,
    28,
    29,
    40,
    41,
    42,
    43,
    44,
    45,
    46,
    47,
    48,
    49,
  ];
  let invalidCodeStartAt = 49;
  let first2 = Number(codeStr.slice(0, 2));
  let shouldCheckStartNum = codeStr.length >= 8;
  if (
    shouldCheckStartNum &&
    (invalidCodeStartWith.indexOf(first2) > -1 || first2 >= invalidCodeStartAt)
  ) {
    return 'errors.coop_code';
  }

  return true;
}

export function validateCoopZip(zip: any) {
  if (typeof zip !== 'number' || String(zip).length !== 4) {
    return 'errors.coop_zip';
  }

  return true;
}
