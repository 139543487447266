import { ACTION_TYPES } from './constants';
import { GiftcardStep1Data, GiftcardStep2Data, IGiftcardOrder } from './types';
import createAction from '../../helpers/createAction';

export default {
  setActiveStepIndex: createAction<number>(ACTION_TYPES.SET_ACTIVE_STEP_INDEX),
  formFill: createAction(
    ACTION_TYPES.FORM_FILL_DATA,
    (step: number, data: Partial<GiftcardStep1Data>) => ({
      type: ACTION_TYPES.FORM_FILL_DATA,
      payload: { step, data },
    }),
  ),
  formSubmit: createAction(
    ACTION_TYPES.FORM_SUBMIT,
    function (
      step: number,
      data: Partial<GiftcardStep1Data | GiftcardStep2Data>,
    ) {
      return { payload: { step, data } };
    },
  ),
  updateOrder: createAction<Partial<IGiftcardOrder>>(ACTION_TYPES.UPDATE_ORDER),
  completeStep: createAction<number>(ACTION_TYPES.COMPLETE_STEP),
};
