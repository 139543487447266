import { call, put, select, takeLatest } from '@redux-saga/core/effects';
import { all } from 'deepmerge';
import { ApiHttpErrorException } from '../../../../api/Api';
import { bookingAddGuestInfo } from '../../../../api/earlybird';
import { AppState } from '../../../reducers';
import { selectTranslator, TransFn } from '../../languages/trans';
import { selectActiveProduct } from '../../products/reducers/products';
import sharedActions from '../../shared/actions';
import actions from '../actions';
import { BOOKING_STEP1, BOOKING_STEP2 } from '../constants';
import { selectBookingStepData } from '../reducers';

import { BookingStep1, BookingStep2, IBookingMenu } from '../types';
import { validateGiftCodeRequirements } from '../validations';
import { validateSteps } from './index';

function* buildParams(step1: BookingStep1, step2: BookingStep2) {
  const lang: string = yield select((state: AppState) => state.local);
  const order = yield select((state: AppState) => state.booking.order);
  const giftcodes = [...step2.data.giftcodes];
  if (step2.data.last_giftcode && step2.data.last_giftcode !== '') {
    giftcodes.push(step2.data.last_giftcode);
  }

  return {
    hold_id: order.hold_id,
    lang,
    name: step2.data.name,
    email: step2.data.email,
    phone: step2.data.phone,
    is_newsletter: step2.data.is_subscribe,
    seat: step1.data.num_person,
    time: step1.data.time,
    time_end: step1.data.time_end ?? null,
    coop_code: step2.data.coop_code,
    billing_zipcode: step2.data.coop_zip,
    menus: Object.values<IBookingMenu>(step1.data.menus)
      .filter(menu => menu.num_person > 0)
      .map(menu => ({
        id: menu.menu_id,
        count: menu.num_person,
      })),
    giftcodes,
  };
}

async function resolveGiftcardErrorMessage(err, trans?: TransFn) {
  if (!(err instanceof ApiHttpErrorException)) {
    console.log(err);
  }

  if (err.response.status !== 422) {
    return;
  }

  let transKey: string | boolean = false;
  const { msg, fields } = await err.response.json();

  if (fields?.error_message && trans) {
    const msg = fields?.error_message;
    transKey = trans(`errors.bycode-${msg.code}`, msg.data);
  } else if (msg === 'giftcard_validate_resto') {
    transKey = 'errors.error-gc-resto';
  } else if (msg === 'giftcard_validate_expired') {
    transKey = 'errors.error-gc-expired';
  } else if (msg === 'matas_card_validate') {
    transKey = 'errors.error-matas-card';
  } else if (msg === 'giftcard_validate_status') {
    transKey = 'errors.error-gc-status';
  } else if (msg === 'giftcard_validate') {
    transKey = 'errors.error-gc-user';
  } else if (msg === 'giftcard_validate_user') {
    transKey = 'errors.error-gc-newuser';
  } else if (msg === 'giftcard_validate_maxuse') {
    transKey = 'errors.error-gc-maxuse';
  } else if (msg.toLowerCase().indexOf('is invalid') > -1) {
    transKey = 'errors.gc-invalid';
  } else {
    transKey = msg;
  }

  return transKey;
}

function* taskValidateGiftcode(
  action: ReturnType<typeof actions.giftcodeValidateRequest>,
) {
  yield put(actions.loading(true));

  const trans = yield select(selectTranslator);
  const step2 = yield select(selectBookingStepData, BOOKING_STEP2);
  let errors = {};
  try {
    errors = yield call(validateGiftCodeRequirements, step2);
  } catch (e) {
    yield put(sharedActions.notifyAlert(trans('message.all_hold_warning')));
    return;
  }

  if (Object.values(errors).length > 0) {
    yield put(actions.setStepErrors(BOOKING_STEP2, errors));
  } else {
    yield put(actions.setStepErrors(BOOKING_STEP2, {}));

    // validate giftcode via api
    const { giftcode } = action.payload;
    const product = yield select(selectActiveProduct);
    const step1 = yield select(selectBookingStepData, BOOKING_STEP1);
    const trans = yield select(selectTranslator);

    try {
      const params = yield buildParams(step1, step2);
      const result = yield call(bookingAddGuestInfo, product.id, params);
      yield put(actions.giftcodeValidateComplete(giftcode));
      yield put(actions.orderHold(result));

      // alert success message
      yield put(
        sharedActions.notifyOpen({
          title: trans('gc_success_msg', { code: giftcode }),
          id: 'alert-modal',
          buttons: [],
        }),
      );
    } catch (e) {
      const validateMsg = yield call(resolveGiftcardErrorMessage, e, trans);
      if (
        validateMsg !== true &&
        validateMsg.toLowerCase() === 'no available tables'
      ) {
        yield put(
          sharedActions.notifyAlert(trans('errors.no_table_available')),
        );
      }

      if (validateMsg !== true) {
        yield put(
          actions.setStepErrors(BOOKING_STEP2, {
            last_giftcode: validateMsg,
          }),
        );
      }
    }
  }

  yield put(actions.loading(false));
}

function* taskRemoveGiftcode(
  action: ReturnType<typeof actions.giftcardRemoveRequest>,
) {
  yield put(actions.loading(true));

  const product = yield select(selectActiveProduct);
  const step1 = yield select(selectBookingStepData, BOOKING_STEP1);
  const step2 = yield select(selectBookingStepData, BOOKING_STEP2);

  try {
    const params = yield buildParams(step1, step2);
    const result = yield call(bookingAddGuestInfo, product.id, params);
    yield put(actions.orderHold(result));
  } catch (e) {
    yield put(
      sharedActions.notifyAlert(
        'Unable to remove giftcode, Please reload and try again',
      ),
    );
    console.error(e);
  }
  yield put(actions.loading(false));
}

function* taskFormSubmit(action: any) {
  if (action.payload.step !== BOOKING_STEP2) {
    return;
  }

  // validations
  const step1Data = yield select(selectBookingStepData, BOOKING_STEP1);

  const { isComplete } = yield all([call(validateSteps, action)]);

  if (!step1Data.complete || !isComplete) {
    // NOTE: maybe we can move the validation here and set the step to complete
    return;
  }

  yield put(actions.loading(true));
  const trans = yield select(selectTranslator);

  // submit guest info
  try {
    const stepData = yield select(selectBookingStepData, BOOKING_STEP2);
    const product = yield select(selectActiveProduct);
    const params = yield buildParams(step1Data, stepData);
    const result = yield call(bookingAddGuestInfo, product.id, params);
    yield put(actions.orderHold(result));
    yield put(actions.moveToNextStep());
  } catch (e) {
    const validateMsg = yield call(resolveGiftcardErrorMessage, e);
    if (validateMsg !== true) {
      yield put(
        actions.setStepErrors(BOOKING_STEP2, {
          last_giftcode: validateMsg,
        }),
      );
    } else {
      yield put(sharedActions.notifyAlert(trans('message.all_hold_warning')));
    }

    console.error(e);
  }

  yield put(actions.loading(false));
}

export default [
  takeLatest(actions.giftcodeValidateRequest.type, taskValidateGiftcode),
  takeLatest(actions.giftcardRemoveRequest.type, taskRemoveGiftcode),
  takeLatest([actions.formSubmit.type], taskFormSubmit),
];
