import rootReducers from './reducers';
import sagas from './sagas';
import actions from './actions';
export { ACTION_TYPES } from './constants';

const reducers = {
  filters: rootReducers,
};

export { actions, reducers, sagas };
