import { ActiveFiltersType, IAreas, IFilterState, ISort } from './types';
import defaultState, { defaultSelectedFilter } from './defaultState';
import { createSelector } from 'reselect';
import { _get } from '../../../utils/object-prop';
import { IFilterOption } from '../shared/types';
import { AppState } from '../../reducers';
import { selectInStockProductStats } from '../products/reducers/products';
import createReducer from '../../helpers/createReducer';
import actions from './actions';

export const filterInitialState: IFilterState = defaultState;

export const rootReducer = createReducer<IFilterState>(filterInitialState, {
  [actions.resetAll]: function (
    state,
    { payload }: ReturnType<typeof actions.resetAll>,
  ) {
    if (!payload) {
      payload = {};
    }

    state.activeFilters = {
      ...defaultSelectedFilter,
      ...payload,
    };
    return state;
  },
  [actions.selectSort]: function (
    state,
    { payload }: ReturnType<typeof actions.selectSort>,
  ) {
    state.activeFilters.sort = payload;
    return state;
  },
  [actions.selectPerson]: function (
    state,
    { payload }: ReturnType<typeof actions.selectPerson>,
  ) {
    state.activeFilters.person = payload;
    return state;
  },
  [actions.selectAreas]: function (
    state,
    { payload }: ReturnType<typeof actions.selectAreas>,
  ) {
    state.activeFilters.areas = payload;
    return state;
  },
  [actions.selectTime]: function (
    state,
    { payload }: ReturnType<typeof actions.selectTime>,
  ) {
    state.activeFilters.times = payload;
    return state;
  },
  [actions.selectTags]: function (
    state,
    { payload }: ReturnType<typeof actions.selectTags>,
  ) {
    state.activeFilters.tags = payload;

    // group new tags by category
    // state.activeFilters.tagsByCategory = {
    //   payload
    // }
    return state;
  },
  [actions.toggleTag]: function (
    state,
    { payload }: ReturnType<typeof actions.toggleTag>,
  ) {
    state.activeFilters.tags = state.activeFilters.tags.filter(
      tagId => tagId !== payload,
    );
    return state;
  },
  [actions.updateTagsByCategory]: function (
    state,
    { payload }: ReturnType<typeof actions.updateTagsByCategory>,
  ) {
    state.activeFilters.tagsByCategory = payload;
    return state;
  },
});

export default rootReducer;

export type FeatureFilterState = ReturnType<typeof rootReducer>;

export const timeSearchParamParser = (time: string | null): string | null => {
  if (!time) {
    return time;
  }
  const timePattern = /^(\d{1,2}).(\d{2})$/;
  const parse = timePattern.exec(time);
  if (!parse || parse.length < 3) {
    return null;
  }

  return `${parse[1]}:${parse[2]}:00`;
};

export const selectActiveFilter = createSelector(
  [(state: FeatureFilterState) => state.activeFilters],
  filters => filters,
);
/**
 * Return's object
 * @param state
 * @param searchString
 */
export const selectActiveInUrlSearch = createSelector(
  [
    (state: FeatureFilterState) => state.areas,
    (state: FeatureFilterState) => state.times,
    (state: FeatureFilterState) => state.persons,
    (state: FeatureFilterState) => state.sorts.entities.sorts,
    (state: FeatureFilterState, searchString: string) => searchString,
  ],
  (areas, times, persons, sorts, searchString) => {
    const searchObj = new URLSearchParams(searchString);
    const params: Partial<ActiveFiltersType> = {};

    const timeStart = timeSearchParamParser(searchObj.get('time-start'));
    const timeEnd = timeSearchParamParser(searchObj.get('time-end'));

    if (timeStart && timeEnd) {
      params.times = [timeStart, timeEnd];
    }

    // let areaParam = searchObj.get('area');
    // if (areaParam !== null) {
    //   let areaId = parseInt(areaParam);
    //   let area = areas.find(a => a.id === areaId);
    //   if (area) {
    //     params.area = {id: area.id, slug: area.slug}
    //   }
    // }

    const personParam: string | null = searchObj.get('person');
    if (personParam) {
      const numPerson = parseInt(personParam);
      const person = persons.find(a => a.id === numPerson);
      if (person) {
        params.person = person.id;
      }
    }

    const sortOp = searchObj.get('sort');
    let sort: ISort;
    if (sortOp && (sort = _get(sorts, sortOp))) {
      params.sort = sort.id;
    }

    const tags = searchObj.getAll('tags');
    if (tags.length > 0) {
      params.tags = tags.map(parseInt);
    }

    return params;
  },
);

const selectActiveFilters = (state: FeatureFilterState) => state.activeFilters;

export const selectActiveInParamSearchOrDefault = createSelector(
  [selectActiveFilters, selectActiveInUrlSearch],
  (lastActiveFilter, selectActiveInParamSearch) => {
    return {
      ...lastActiveFilter,
      ...selectActiveInParamSearch,
    };
  },
);

export const selectSortFilterOptions = createSelector(
  [(state: FeatureFilterState) => state.sorts.entities.sorts],
  (sorts): IFilterOption[] => {
    return Object.values(sorts || []).map(sort => ({
      id: sort.id.toString(),
      label: sort.label,
    }));
  },
);

export const selectAreaFilterOptions = createSelector(
  [(state: AppState) => state.filters.areas, selectInStockProductStats],
  (areas: IAreas, stats): IFilterOption<number>[] => {
    const result: IFilterOption<number>[] = [];
    areas.forEach(value => {
      const stat = _get(stats.areas, value.id, { total: 0 }).total;
      result.push({
        ...value,
        label: `${value.label} (${stat})`,
      });
    });

    return result;
  },
);

export const selectAllAreas = createSelector(
  [(state: AppState) => state.filters.areas],
  areas => areas,
);
