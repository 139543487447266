import {
  reducers as pageBoxReducers,
  sagas as pageBoxSagas,
} from './pageBoxes';
import { all } from 'redux-saga/effects';
import { reducers as boxReducers } from './box';

export const reducers = {
  ...pageBoxReducers,
  box: boxReducers,
};

export const sagas = function* rootSagas() {
  yield all([pageBoxSagas()]);
};
