import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { ApiParams, ApiResponseTypes } from '../../../api/Api';
import { AppState } from '../../reducers';
import createAction from '../../helpers/createAction';
import createReducer from '../../helpers/createReducer';
import { DeepPartial } from 'redux';
import { mergeDeep } from '../../../utils/object-prop';

export const ACTION_TYPES = {
  LOADING: 'pagination/loading',
  NEXT_PAGE_REQUESTED: 'pagination/nextPageRequested',
  NEXT_PAGE_COMPLETED: 'pagination/nextPageCompleted',
  NEXT_PAGE_FAILED: 'pagination/nextPageFailed',
  PARAMETERS_UPDATED: 'pagination/parametersUpdated',
  updateParamsAndReset: 'updateParamsAndReset',
};

export type PaginationData = any;

export type PaginatedEntity<T extends any = any> = {
  loading: boolean;
  per_page: number;
  current_page: number;
  total_data: number;
  next_page: number | null;
  data: T[];
  params: ApiParams;
};

export interface IPaginationState<T = any, TExtra = any> {
  defaultGroup: string;
  results: Record<string, PaginatedEntity<T>>;
  extra?: TExtra;
}

export const createPaginationDefaultState = <T = {}>(
  state: DeepPartial<IPaginationState<T>>,
): IPaginationState<T> => {
  return mergeDeep<IPaginationState<T>>(
    {
      defaultGroup: 'all',
      results: {
        all: {
          loading: false,
          params: {},
          current_page: 0,
          next_page: 1,
          per_page: 6,
          total_data: 0,
          data: [],
        },
      },
    },
    state,
  );
};

const makePaginationActions = <ApiData extends PaginationData>(
  name,
  actions?: Record<string, ReturnType<typeof createAction>>,
) => ({
  ...actions,
  loading: createAction(
    `${name}/${ACTION_TYPES.LOADING}`,
    (isLoading: boolean, group = 'all') => ({
      payload: isLoading,
      meta: { type: name, group },
    }),
  ),
  updateParamsAndReset: createAction(
    `${name}/${ACTION_TYPES.updateParamsAndReset}`,
    (params?: ApiParams, group = 'all') => ({
      payload: params,
      meta: { type: name, group },
    }),
  ),
  nextPageRequest: createAction(
    `${name}/${ACTION_TYPES.NEXT_PAGE_REQUESTED}`,
    (params?: ApiParams, group = 'all') => ({
      payload: params,
      meta: { type: name, group },
    }),
  ),
  nextPageComplete: createAction(
    `${name}/${ACTION_TYPES.NEXT_PAGE_COMPLETED}`,
    (
      result: Omit<PaginatedEntity<ApiData>, 'loading' | 'params' | 'per_page'>,
      group = 'all',
    ) => ({
      payload: result,
      meta: { type: name, group },
    }),
  ),
  /**
   * when group is null it means the action will apply the parameter to all results
   * @param {ApiParams} params
   * @param {string|null} group
   */
  updateParams: createAction(
    `${name}/${ACTION_TYPES.PARAMETERS_UPDATED}`,
    (params: ApiParams, group: string | null = null) => ({
      payload: params,
      meta: { type: name, group },
    }),
  ),
});

const makePaginationReducer = (
  initialState,
  actions,
  extra?: Record<string, any>,
) =>
  createReducer<IPaginationState>(initialState, {
    // @ts-ignore
    ...extra,
    [actions.updateParamsAndReset]: function (
      state,
      { payload, meta }: ReturnType<typeof actions.updateParamsAndReset>,
    ) {
      const group = meta?.group ?? state.defaultGroup;

      state.results[group].next_page = 1;
      state.results[group].current_page = 1;
      state.results[group].total_data = 0;
      state.results[group].loading = true;
      state.results[group].data = [];
      state.results[group].params = {
        ...state.results[group].params,
        ...payload,
      };

      return state;
    },
    [actions.loading]: function (
      state,
      { payload, meta }: ReturnType<typeof actions.loading>,
    ) {
      const group = meta?.group ?? state.defaultGroup;
      state.results[group].loading = payload;
      return state;
    },
    [actions.nextPageRequest]: function (
      state,
      { payload, meta }: ReturnType<typeof actions.nextPageRequest>,
    ) {
      const group = meta?.group ?? state.defaultGroup;
      // checks if has next page
      if (state.results[group].next_page === null) {
        return state;
      }

      state.results[group].loading = true;
      // this payload api params is for the saga
      if (payload) {
        state.results[group].params = {
          ...state.results[group].params,
          ...payload,
        };
      }

      return state;
    },
    [actions.nextPageComplete]: function (
      state,
      { payload, meta }: ReturnType<typeof actions.nextPageComplete>,
    ) {
      const group = meta?.group ?? state.defaultGroup;

      const { next_page, current_page, data, total_data } = payload;
      state.results[group].next_page = next_page;
      state.results[group].current_page = current_page;
      state.results[group].total_data =
        total_data + state.results[group].total_data;
      state.results[group].loading = false;

      if (data.length > 0) {
        // @ts-ignore find solution how to fix this
        // Error: Argument of type 'ApiData' is not assignable to parameter of type 'Draft<ApiData>'.
        state.results[group].data.push(...data);
      }

      return state;
    },
    [actions.updateParams]: function (
      state,
      { meta, payload }: ReturnType<typeof actions.updateParams>,
    ) {
      if (!meta?.group) {
        Object.keys(state.results).forEach(group => {
          state.results[group].params = {
            ...state.results[group].params,
            ...payload,
          };
        });
      } else if (payload) {
        const group = meta?.group ?? state.defaultGroup;
        const result = state.results[group];
        result.params = { ...result.params, ...payload };
      }

      return state;
    },
  });

export const createPaginationSlice = <ApiData extends PaginationData>(
  name: string,
  initialState: IPaginationState<ApiData>,
  apiCall: (params: ApiParams) => Promise<ApiResponseTypes<ApiData>>,
  extraActions?: Record<string, ReturnType<typeof createAction>>,
  extraReducer?: Record<string, any>,
  extraSagas?: (actions: ReturnType<typeof makePaginationActions>) => any[],
) => {
  const actions = makePaginationActions<ApiData>(name, extraActions);
  const reducers = makePaginationReducer(initialState, actions, extraReducer);
  const sagas = () => {
    function* taskNextPage({
      meta,
    }: ReturnType<typeof actions.nextPageRequest>) {
      try {
        const state = yield select((state: AppState) => state[name]);
        const local = yield select((state: AppState) => state.local);
        const group = meta?.group ?? state.defaultGroup;
        const result = state.results[group];
        const params = {
          ...result.params,
          current_page: result.next_page,
          per_page: result.per_page,
          local,
        };

        const response = yield call(apiCall, params);
        yield put(
          actions.nextPageComplete(response, meta?.group ?? state.defaultGroup),
        );
      } catch (e) {
        console.warn(e);
      }
    }

    return function* watchers() {
      yield all([
        takeEvery(actions.nextPageRequest.type, taskNextPage),
        takeEvery(actions.updateParamsAndReset.type, taskNextPage),
        // @ts-ignore
        ...(extraSagas ? extraSagas(actions) : []),
      ]);
    };
  };

  return {
    actions,
    reducers,
    sagas: sagas(),
  };
};
