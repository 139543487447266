import { createSelector } from 'reselect';
import uniqueBy from 'utils/uniqueBy';
import { TimeUtil } from '../../../../utils/date';
import { _get, mergeDeep } from '../../../../utils/object-prop';
import { AppState } from '../../../reducers';
import { ICategory, ICategoryTag } from '../../filters/categories/types';
import { ITag } from '../../filters/types';
import { selectTranslator, TransFn } from '../../languages/trans';
import { IFilterOption, ProductId } from '../../shared/types';
import { ACTION_TYPES, CHANGE, iniState } from '../constants';
import {
  IChangeProductListPayload,
  IProduct,
  IProductEntity,
  IProductImage,
  IProductMenu,
  IProductMenuDisplay,
  IProductMenus,
  IProductRecords,
  IProducts,
  IProductSeat,
} from '../types';
import {
  productApplyFilters,
  ProductFilterMatchKeys,
  ProductSortFnParam,
  productSortMatcher,
  productSortMoveAllUnMatchFilterBeforeSoldout,
  soldProductsSortByFilterTypesRank,
} from './filters/product-filters';
import { selectActiveProductMenu, selectProductMenuById } from './productInfos';

const { entities: products } = iniState;

export default (
  state: IProductEntity = products,
  action: IChangeProductListPayload,
): IProductEntity => {
  if (action.type === CHANGE) {
    return action.payload;
  } else if (action.type === ACTION_TYPES.UPDATE_PRODUCT_MENUS) {
    return mergeDeep(state, action.payload);
  } else {
    return state;
  }
};

/**
 * All selectors related to products
 */

/**
 * Return all product entities
 * @param state
 */
export const selectAllProducts = (state: AppState) =>
  state.products.entities.products;

/**
 * Return all product slugs
 * @param state
 */
export const selectProductSlugs = (state: AppState) =>
  state.products.entities.slugs;

/**
 * Return product all id by active Type
 * @return string[]
 */
export const selectActiveProductsIdsByType = createSelector(
  [
    (state: AppState) => state.products.activeProductType,
    (state: AppState) => state.products.entities.types,
  ],
  (typeId, idsByType): ProductId[] => {
    return _get(idsByType, typeId, []);
  },
);

/**
 * Return default product id
 * @param state
 */
export const selectActiveProductId = createSelector(
  [
    (state: AppState) => state.products.activeProduct,
    selectActiveProductsIdsByType,
  ],
  (activeProduct, productIds): ProductId | null => {
    return activeProduct.id || _get(productIds, 0, 0);
  },
);

/**
 * Return active filters
 * @param state
 */
export const selectFilters = (state: AppState) => state.filters.activeFilters;

/**
 * Return active id by slug, if slug is not found it will default active id
 * @param state
 * @param slug
 */
export const selectActiveProductFromParamSlugOrDefault = (
  state: AppState,
  slug: any | string,
) => {
  let foundSlugChanges: any = null;
  if (slug && state.products.activeProduct.slug !== slug) {
    foundSlugChanges = _get(state.products.entities.slugs, slug);
    return foundSlugChanges
      ? foundSlugChanges.id
      : state.products.activeProduct.id;
  }
  return state.products.activeProduct.id;
};

/**
 * Return active product stock entity
 * @return IProduct | undefined
 */
export const selectActiveProduct = createSelector(
  [selectActiveProductId, selectAllProducts, (state: AppState) => state.local],
  (productId: ProductId | null, allProducts, local): IProduct | null => {
    if (!productId) {
      return null;
    }

    const product = _get<IProduct>(allProducts, productId);
    if (!product) {
      return null;
    }

    if (local !== 'da') {
      return {
        ...product,
        route: `/${local}${product.route}`,
      };
    }

    return product;
  },
);

export const selectIsUnderServing = createSelector(
  [selectActiveProduct, (state: AppState) => state.categories.entities.tags],
  (product, tags) => {
    if (!product) {
      return false;
    }

    return product.tags.find(tag => {
      if (!tags.hasOwnProperty(tag.id)) {
        return false;
      }
      const data = tags[tag.id];
      const isHidden = data.hasOwnProperty('hidden') ? data.hidden > 0 : false;
      return !isHidden && data.slug.toLowerCase() === 'udeservering';
    });
  },
);
/**
 * Return active product image by device
 * @return IProduct | undefined
 */
export const selectActiveImageByDevice = () => {
  return createSelector(
    [
      (state: AppState, productId: number | undefined) =>
        productId ? productId : selectActiveProductId(state),
      selectAllProducts,
      // select device type
      (state: AppState) => state.deviceType,
    ],
    (productId, products: IProducts, deviceType): IProductImage | undefined => {
      if (!productId) {
        return undefined;
      }

      const product = _get<IProduct>(products, productId);
      if (!product) {
        return undefined;
      }

      let imgUrl = product.primary_image;
      if (deviceType === 'mobile') {
        imgUrl = product.mobile_primary_image;
      }

      return {
        type: 'primary',
        url: imgUrl,
        alt: product.name,
      };
    },
  );
};

/**
 * Return active product stock entity
 * @return IProduct | undefined
 */
export const makeSelectProductBySlug = () =>
  createSelector(
    [
      selectAllProducts,
      selectProductSlugs,
      selectActiveProductFromParamSlugOrDefault,
      (state: AppState) => state.partners.results.data,
    ],
    (allProducts, productSlugs, productId, partners) => {
      if (!productId || !productSlugs) {
        return undefined;
      }

      return allProducts[productId];
    },
  );
// export const selectProductBySlug = makeSelectProductBySlug()

/**
 * Return active product stock entity
 * @return IProduct | undefined
 */
export const makeSelectProductById = () => {
  return createSelector(
    [selectAllProducts, (state: AppState, productId: ProductId) => productId],
    (allProducts, productId): IProduct | null => {
      return allProducts[productId];
    },
  );
};

export const selectFilteredProductIds = createSelector(
  [
    (state: AppState) => state.date_time.split('T')[1],
    selectAllProducts,
    selectFilters,
  ],
  (
    timeNow,
    allProducts,
    filters,
  ): { id: ProductId; result: ProductFilterMatchKeys }[] => {
    const currentTime = TimeUtil.parse(timeNow);
    return (
      Object.values(allProducts || {})
        // sort by filter
        .sort((a, b) => productSortMatcher(a, b, filters.sort))
        // move all not in-stock status to the end of list
        // .sort(productSortMoveAllSoldToEnd)
        // apply filter
        .map(
          (product): ProductSortFnParam => {
            // console.log(
            //   product.slug,
            //   productApplyFilters(product, filters, currentTime),
            // );

            return {
              id: product.id,
              status: product.status,
              slug: product.slug,
              result: productApplyFilters(product, filters, currentTime),
            };
          },
        )
        .sort(soldProductsSortByFilterTypesRank)
        // move all filtered result before soldout
        .sort(productSortMoveAllUnMatchFilterBeforeSoldout)
    );
  },
);

export const selectCountFilteredProducts = createSelector(
  [selectFilteredProductIds],
  products => {
    let totalFiltered = 0;
    let total = 0;

    products.forEach(product => {
      // total counter doesn't include soldout
      if (product.result.indexOf('status') > -1) {
        return;
      }

      // count instock products
      total++;

      // count filtered
      if (product.result.length > 0) {
        totalFiltered++;
      }
    });

    return [total - totalFiltered, total];
  },
);

export const selectProductTags = createSelector(
  [(state: AppState) => state.categories.entities.tags, selectActiveProduct],
  (tags, product: IProduct | null) => {
    if (!product || !Array.isArray(product.tags)) {
      return [];
    }

    const productTags: Record<number, ITag> = {};
    product.tags.forEach(tag => {
      const tagInfo = _get<ICategoryTag>(tags, tag.id);
      if (tagInfo && tagInfo.hidden === 0) {
        productTags[tag.id] = {
          ...tagInfo,
          id: tagInfo.id,
          label: tagInfo.name,
        };
      }
    });

    return Object.values(productTags);
  },
);

const generateProductMenuPreview = (
  product: IProduct,
  productMenus: IProductMenus,
  trans: TransFn,
  menuInfo: IProductMenus,
): IProductMenuDisplay[] => {
  // const { menus } = product;
  const route = product.route + '?view=menu';

  const menus = Object.values(productMenus);
  const menuMapper2 = (menu: IProductMenu, index) => {
    return {
      id: menu.id,
      name: menu.name,
      menu_type: menu.menu_type,
      route: menu.route + '&i=' + index,
      price_regular: menu.price_regular,
      price_sale: menu.price_sale,
    };
  };

  if (menus.length === 0) {
    return [];
  }

  const menuList = Object.values(productMenus)
    .map(menuMapper2)
    .filter(Boolean)
    .sort((a, b) => {
      const aSale = a.price_regular;
      const bSale = b.price_regular;
      return aSale === bSale ? 0 : aSale > bSale ? 1 : -1;
    })
    .sort((a, b) => {
      // a less than b
      if (a.menu_type === 'LUNCH' && b.menu_type !== 'LUNCH') {
        return 1;
      }

      // a greater than b
      if (a.menu_type !== 'LUNCH' && b.menu_type === 'LUNCH') {
        return -1;
      }

      return 0;
    });

  if (menuList.length === 0) {
    return [];
  }

  if (menus.length <= 2) {
    return uniqueBy(menuList, item => item.name);
  }

  return [
    menuList[0],
    {
      id: 0,
      name: `+${menus.length - 1} ${trans('more_menu')}`,
      route: route + '&i=0',
    },
  ];
};

export const getProductMenuListPreview = createSelector(
  [
    selectActiveProduct,
    selectActiveProductMenu,
    selectTranslator,
    (state: AppState) => state.products.productInfos.menus,
  ],
  (
    product: IProduct | null,
    productMenus,
    trans,
    menuInfo,
  ): IProductMenuDisplay[] => {
    if (!product) {
      return [];
    }

    const m: IProductMenus = {};

    productMenus.forEach(v => {
      if (product.menus.indexOf(v.id) > -1) {
        m[v.id] = v;
      }
    });

    console.log(product.menus.map(id => m[id]));

    return generateProductMenuPreview(product, m, trans, menuInfo);
  },
);

export const makeSelectProductMenuListPreviewById = () => {
  return createSelector(
    [
      (state: AppState, productId: number) =>
        state.products.entities.products[productId],
      selectProductMenuById,
      selectTranslator,
      (state: AppState) => state.products.productInfos.menus,
    ],
    (
      product: IProduct,
      productMenus,
      trans,
      menuInfo,
    ): IProductMenuDisplay[] => {
      if (!product) {
        return [];
      }

      const m: IProductMenus = {};
      productMenus.forEach(v => {
        if (product.menus.indexOf(v.id) > -1) {
          m[v.id] = v;
        }
      });

      return generateProductMenuPreview(product, m, trans, menuInfo);
    },
  );
};

export const selectIsAllOutOfStock = createSelector(
  [
    (state: AppState) => state.products.productIds,
    (state: AppState) => state.products.entities.products,
  ],
  (ids: ProductId[], products: IProducts): boolean => {
    for (let i = 0; i < ids.length; i++) {
      const product = _get(products, ids[i]);
      if (product && product.status === 'in-stock') {
        return false;
      }
    }
    return true;
  },
);

export const mergeProductStats = (
  prevResult: ProductStats,
  product: IProduct,
): ProductStats => {
  const inStockTimes = product.times.filter(t => {
    return t.status === 'in-stock';
  });

  if (inStockTimes.length === 0) {
    return prevResult;
  }

  const { areas, tags } = prevResult;

  const uniqTags = [...new Set(product.tags.map(tag => tag.id))];
  uniqTags.forEach(tagId => {
    const tagResult: ProductStatResult = tags[tagId] || {
      id: tagId,
      total: 0,
      products: [],
    };

    tagResult.total += 1;
    tagResult.products.push(product.id);
    prevResult.tags[tagResult.id] = tagResult;
  });

  const areaStats: ProductStatResult = {
    id: product.city_id,
    total: 1,
  };

  if (!areas.hasOwnProperty(product.city_id)) {
    prevResult.areas[product.city_id] = areaStats;
  } else {
    prevResult.areas[product.city_id].total = areas[product.city_id].total + 1;
  }

  if (product.city_tags?.length > 0) {
    product.city_tags.forEach(cityId => {
      if (product.city_id === cityId) {
        return;
      }

      if (areas.hasOwnProperty(cityId)) {
        prevResult.areas[cityId].total = areas[cityId].total + 1;
      } else {
        prevResult.areas[cityId] = {
          id: cityId,
          total: 1,
        };
      }
    });
  }

  return prevResult;
};

export type ProductStatResult = {
  id: number;
  total: number;
  products?: any[];
};
export type ProductStats = {
  areas: Record<number, ProductStatResult>;
  tags: Record<number, ProductStatResult>;
};
export const selectInStockProductStats = createSelector(
  [
    (state: AppState) => state.products.productIds,
    (state: AppState) => state.products.entities.products,
  ],
  (productIds: ProductId[], products: IProductRecords) => {
    let results: ProductStats = {
      areas: {},
      tags: {},
    };

    productIds.forEach(id => {
      const product = products[id];
      if (product.status === 'in-stock') {
        results = mergeProductStats(results, product);
      }
    });
    return results;
  },
);

export const createSelectApplyFilterToProduct = function () {
  return createSelector(
    [
      (state: AppState) => state.date_time.split('T')[1],
      selectFilters,
      (state: AppState, productId: ProductId) =>
        _get(state.products.entities.products, productId, null),
      (state: AppState) => state.categories.entities.categories,
    ],
    (
      timeNow,
      filters,
      product: IProduct | null,
      categories,
    ): string[] | null => {
      if (product === null || product.status !== 'in-stock') {
        return [];
      }

      const { tags } = filters;
      const match = productApplyFilters(
        product,
        filters,
        TimeUtil.parse(timeNow),
      );

      // transform match to translation key except tags
      // because tags will be transform to category names later
      const results: string[] = [];
      if (match.length > 0) {
        match.forEach(type => {
          if (type !== 'status' && type !== 'tags') {
            results.push(`filter_reasons.${type}`);
          }
        });
      }

      // since no filter result for tags we can stop here
      if (match.indexOf('tags') < 0) {
        return results;
      }

      let arrCategories: ICategory[];
      if (categories) {
        arrCategories = Object.values(categories);
      }

      // we will group all tags in filter by their category
      // this will help us check if product is filter by category
      const tagGroup: Record<string, number[]> = {};
      tags.forEach(tagId => {
        const category = arrCategories.find(
          category => category.tags.indexOf(tagId) > -1,
        );
        if (!category) {
          return;
        }

        const { id: catId } = category;
        if (!tagGroup[catId]) {
          tagGroup[catId] = [tagId];
        } else {
          tagGroup[catId].push(tagId);
        }
      });

      Object.entries(tagGroup).forEach(([catId, tagIds]) => {
        const hasCategory =
          product.tags.findIndex(t => tagIds.indexOf(t.id) > -1) > -1;
        if (!hasCategory) {
          results.push(categories[catId].name);
        }
      });

      return results;
    },
  );
};

export const makeSelectProductAvailableTimes = () =>
  createSelector(
    [
      (state: AppState) => state.date_time.split('T')[1],
      (_: AppState, product: IProduct) => product,
    ],
    (timeNow, product) => {
      if (product.status !== 'in-stock') {
        return [];
      }

      const currentTime = TimeUtil.parse(timeNow);
      return uniqueBy(
        product.times,
        item => item.time,
        item => {
          let time = item.time;
          if (item.time_end && product.product_type === 'bar') {
            time = item.time_end;
          }

          return (
            item.status === 'in-stock' && TimeUtil.parse(time).gt(currentTime)
          );
        },
      );
    },
  );

export const makeSelectProductSeatFilterOption = () =>
  createSelector(
    [
      (state: AppState, productId: number) =>
        _get(state.products.entities.products, productId),
      selectTranslator,
    ],
    (product: IProduct | null, trans): IFilterOption<number>[] => {
      if (!product) {
        return [];
      }

      const person = trans('person');
      const persons = trans('persons');
      const notPossible = trans('people_not_possible');
      return [1, 2, 3, 4, 5, 6, 7, 8].map(
        (seat): IFilterOption<number> => {
          let prefix = seat > 1 ? persons : person;
          const find = product.seats.find(
            (pSeat: IProductSeat) => pSeat.seat === seat,
          );
          const isDisabled = !find || _get(find, 'status') !== 'in-stock';
          if (isDisabled) {
            prefix += ` (${notPossible})`;
          }

          return {
            id: seat,
            label: `${seat} ${prefix}`,
            disabled: isDisabled,
          };
        },
      );
    },
  );
