import {
  GiftcardStep1,
  GiftcardStep1Data,
  GiftcardStep2,
  GiftcardStep2Data,
} from './types';
import {
  validateEmail,
  validateName,
  validateNumber,
} from '../shared/validations';
import { PartialString } from '../booking/types';

export const validateStep1 = (
  inputs: GiftcardStep1,
): PartialString<GiftcardStep1Data> => {
  const errors: PartialString<GiftcardStep1Data> = {};

  const {
    data: { amount, quantity },
  } = inputs;

  if (!validateNumber(amount) || amount === 0) {
    errors.amount = 'errors.giftcard_amount';
    return errors;
  }

  if (!validateNumber(quantity) || quantity === 0) {
    errors.amount = 'errors.giftcard_quantity';
    return errors;
  }

  return errors;
};

export const validateStep2 = (
  inputs: GiftcardStep2,
): PartialString<GiftcardStep2Data> => {
  const errors: PartialString<GiftcardStep2Data> = {};
  const {
    data: { name, email, is_accept_terms, email_confirm },
  } = inputs;

  if (!validateName(name)) {
    errors.name = 'errors.name';
    return errors;
  }

  if (!validateEmail(email)) {
    errors.email = 'errors.email';
    return errors;
  }

  if (email !== email_confirm) {
    errors.email_confirm = 'errors.email_confirm';
    return errors;
  }

  if (!is_accept_terms) {
    errors.is_accept_terms = 'errors.accept_terms';
    return errors;
  }

  return errors;
};
