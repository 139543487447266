export default function uniqueBy<T = any>(
  obj: T[],
  getField: (obj: T) => any,
  extraFilter: (obj: T) => boolean = () => true,
): T[] {
  const filtered = {};
  const results = [];
  const objLength = obj.length;

  for (let i = 0; i < objLength; i++) {
    const item = obj[i];
    const objVal = getField(item);

    if (filtered[objVal] || !extraFilter(item)) {
      continue;
    }

    filtered[objVal] = true;
    results.push(item);
  }

  return results;
}
