import { IStateCategory } from './types';
import { AppState } from '../../../reducers';
import { createSelector } from 'reselect';
import { selectInStockProductStats } from '../../products/reducers/products';
import { _get } from '../../../../utils/object-prop';

export const iniState: IStateCategory = {
  allIds: [],
  entities: {
    categories: {},
    tags: {},
  },
};

export default (state: IStateCategory = iniState, action: {}) => {
  return state;
};

export const selectAllCategories = createSelector(
  [(state: AppState) => state.categories.entities.categories],
  item => Object.values(item),
);

export type TagGroup = {
  id: number;
  name: string;
  tags: {
    id: number;
    name: string;
    hidden?: number;
  }[];
};
export const selectAllGroupTags = createSelector(
  [(state: AppState) => state.categories.entities],
  ({ categories, tags }): TagGroup[] => {
    const tagGroup = [];
    const tagSubParents = {};

    // get all sub parent then later push them to tag group according to their weight
    Object.values(tags || {}).forEach(tag => {
      if (tag.parent_id === 0 || !tags[tag.parent_id]) {
        return false;
      }

      if (!tagSubParents[tag.parent_id]) {
        tagSubParents[tag.parent_id] = {
          ...tags[tag.parent_id],
          tags: [],
        };
      }

      tagSubParents[tag.parent_id].tags.push(tag);
    });

    Object.values(categories || {}).forEach(category => {
      // const categoryTags = category.tags
      //   .map(id => {
      //     if (
      //       category.tags.indexOf(id) > -1 &&
      //       tags[id] &&
      //       tags[id].parent_id === 0
      //     ) {
      //       return tags[id];
      //     }
      //
      //     return false;
      //   })
      //   .filter(item => {
      //     return item && item.hidden === 0;
      //   });
      //
      // if (categoryTags.length > 0) {
      //   tagGroup.push({
      //     ...category,
      //     tags: categoryTags,
      //   });
      // }

      Object.values(tagSubParents).forEach(tag => {
        // @ts-ignore
        if (category.tags.indexOf(tag.id) > -1) {
          tagGroup.push(tag);
        }
      });
    });

    return tagGroup;
  },
);

export const selectAllCategoryTags = createSelector(
  [(state: AppState) => state.categories.entities.tags],
  tags => tags,
);
const selectTagsByCategory = (state: AppState, categoryId: number) => {
  const category = state.categories.entities.categories[categoryId];
  const tags = state.categories.entities?.tags;
  return category && tags ? category.tags.map(tagId => tags[tagId]) : [];
};

export const createSelectTagFilterOptionsByCategory = () =>
  createSelector(
    [
      selectTagsByCategory,
      (state: AppState) => selectInStockProductStats(state).tags,
    ],
    (tags, tagStats) => {
      if (!tags) {
        return [];
      }

      // group by parent
      const childTags = {};

      tags.forEach(item => {
        if (item.parent_id === 0) {
          return;
        }
        if (!childTags.hasOwnProperty(item.parent_id)) {
          childTags[item.parent_id] = [];
        }

        childTags[item.parent_id].push(item);
      });

      return tags
        .map(tag => {
          const isLabel = childTags.hasOwnProperty(tag.id);

          if (!isLabel && tag.hasOwnProperty('hidden') && tag.hidden > 0) {
            return null;
          }

          const stats = _get(tagStats, tag.id, { total: 0 }).total;
          return {
            id: tag.id,
            label: `${tag.name} (${stats})`,
            isLabel,
          };
        })
        .filter(Boolean);
    },
  );
