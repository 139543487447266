import {
  ActiveFiltersType,
  IFilterState,
  IPersons,
  ISort,
  ITimeRangeFilter,
} from './types';
import { TimeUtil } from '../../../utils/date';

const maxPerson = 8;

export const defaultSelectedFilter: ActiveFiltersType = {
  areas: [],
  times: ['00:00:00', '23:45:00'],
  sort: '0',
  person: 0,
  tags: [],
  tagsByCategory: {},
  search: '',
};

export const defaultPersons: IPersons = (function (): IPersons {
  const persons: IPersons = [
    {
      id: 0,
      label: 'people',
      display_label: 'people',
    },
  ];
  let maxNum = 0;
  while (maxPerson > maxNum) {
    maxNum++;
    persons.push({
      id: maxNum,
      label: `${maxNum} ${maxNum === 1 ? 'person' : 'people'}`,
    });
  }
  return persons;
})();

const startTime = new TimeUtil('10:00:00');
const endTime = new TimeUtil('23:45:00');
const defInterval = 30 * 60;
export const defaultTimeList: ITimeRangeFilter = {
  start: startTime,
  end: endTime,
  interval: defInterval, // 30 minutes
  time_list: [
    '10:00:00',
    '11:30:00',
    '12:00:00',
    '12:30:00',
    '13:00:00',
    '13:30:00',
    '14:00:00',
    '14:30:00',
    '15:00:00',
    '15:30:00',
    '16:00:00',
    '16:30:00',
    '17:00:00',
    '17:30:00',
    '18:00:00',
    '18:30:00',
    '19:00:00',
    '19:30:00',
    '20:00:00',
    '20:30:00',
    '21:00:00',
    '21:30:00',
    '22:00:00',
    '22:30:00',
    '23:00:00',
    '23:30:00',
    '23:45:00',
  ],
};

export const defaultSortEntity: ISort = {
  id: '0',
  label: 'Sortér',
};

const defaultState: IFilterState = {
  persons: defaultPersons,
  areas: [],
  times: defaultTimeList,
  sorts: {
    allIds: ['0'],
    entities: {
      sorts: {
        [defaultSortEntity.id]: defaultSortEntity,
      },
    },
  },
  tags: [],
  activeFilters: defaultSelectedFilter,
};

export default defaultState;
