import { IProductState } from './types';

export const TOGGLE_VIEW_INFO = 'PRODUCTS/TOGGLE_VIEW_INFO';
export const SET_ACTIVE = 'PRODUCTS/SET_ACTIVE';
export const CHANGE = 'PRODUCTS/CHANGE_LIST';
export const CHANGE_PRODUCT_TYPE = 'PRODUCTS/CHANGE_TYPE';
export const LOADING = 'PRODUCTS/LOADING';
export const ADD_PRODUCT_INFO = 'PRODUCTS/ADD_INFO';
export const UPDATE_PRODUCT_INFO_MENUS = 'PRODUCTS/UPDATE_INFO_MENUS';

export const ACTION_TYPES = {
  TOGGLE_VIEW_INFO,
  SET_ACTIVE,
  CHANGE,
  CHANGE_PRODUCT_TYPE,
  LOADING,
  ADD_PRODUCT_INFO,
  UPDATE_PRODUCT_INFO_MENUS,
  UPDATE_PRODUCT_MENUS: 'PRODUCTS/UPDATE_PRODUCT_MENUS',
  VIEWING: 'products/viewingProductInfo',
};

export const iniState: IProductState = {
  loading: false,
  entities: {
    products: {},
    menus: {},
    types: {},
    slugs: {},
  },
  productIds: [],
  productInfos: {
    products: {},
    menus: {},
    courses: {},
    types: {},
    slugs: {},
  },
  activeProduct: { id: 0, slug: '' },
  viewInfo: false,
  activeProductType: 'restaurant',
  productTypes: {
    restaurant: {
      id: 'restaurant',
      label: 'restauranger',
      url: {
        en: 'restaurants',
        da: 'restauranger',
      },
      supported_city: [],
      city_config: [],
    },
    bar: {
      id: 'bar',
      label: 'barer',
      url: {
        en: 'bars',
        da: 'barer',
      },
      supported_city: [],
      city_config: [],
    },
  },
};
