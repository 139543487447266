import { AppState } from '../../reducers';
import { createSelector } from 'reselect';
import { _get } from '../../../utils/object-prop';
import tokenParser from '../../../utils/tokenParser';

export type TokenRecords = Record<string, string>;

export type ITokenState = Record<string, TokenRecords>;

export const defaultTokenState: ITokenState = {
  box: {},
};

const reducers = {
  tokens: (state: ITokenState = {}) => state,
};

export { reducers };

export const makeSelectResolveTokenFromString = () =>
  createSelector(
    [
      (state: AppState, _, type = 'box') =>
        _get<TokenRecords>(state.tokens, type, {}),
      (_: AppState, content: string) => content,
    ],
    (tokens, content) => {
      const foundTokens = tokenParser(content);
      foundTokens.forEach(([pattern, tokenKey]) => {
        content = content.replace(pattern, tokens?.[tokenKey] ?? '');
      });

      return content;
    },
  );
