export const ACTION_TYPES = {
  SELECT_AREAS: 'filters/SelectAreas',
  SELECT_TIME: 'filters/SelectTime',
  RESET: 'filters/reset',
  SELECT_PERSON: 'filters/SelectPerson',
  SELECT_AREA: 'filters/SelectArea',
  SELECT_SORT: 'filters/sort',

  TOGGLE_TAG: 'filters/ToggleTag',
  SELECT_TAG: 'filters/SelectTag',
  DESELECT_TAG: 'filters/DeselectTag',
  SET_SELECTED_TAG: 'filters/SetSelectedTag',
  TAGS_BY_CATEGORY_UPDATED: 'filters/TagsByCategoryUpdated',
};
