/**
 * public selectors for filter & sorting products
 */

import { IProduct, IProductAvailableTime } from '../../types';
import { ActiveFiltersType, FilterSortTypes } from '../../../filters/types';
import { TimeLiteral, TimeUtil } from '../../../../../utils/date';

const hasAvailableSeat = (
  availableTimes: IProductAvailableTime[],
  seat: number,
) => {
  const findSeat = (seatNum: number) => {
    return seat === seatNum;
  };
  const hasSeat = (time: IProductAvailableTime) => {
    return time.seats.find(findSeat);
  };

  return availableTimes.findIndex(hasSeat) > -1;
};

/**
 * Return array of filter keys
 * @param product
 * @param filter
 */
export const ProductRestaurantFilter = [
  'status',
  'area',
  'times',
  'person',
  'tags',
];
export type ProductFilterTypes =
  | 'status'
  | 'area'
  | 'times'
  | 'person'
  | 'tags';
export type ProductFilterMatchKeys = ProductFilterTypes[];
export const productApplyFilters = (
  product: IProduct,
  filter: ActiveFiltersType,
  currentTime: TimeLiteral,
): Partial<ProductFilterTypes[]> => {
  const whyFiltered: ProductFilterMatchKeys = [];
  if (product.status !== 'in-stock') {
    whyFiltered.push('status');
    // stop here, no need to check if already sold
    return whyFiltered;
  }

  const { areas, person, times, tags, tagsByCategory } = filter;

  const pTime = (a: IProductAvailableTime) => {
    return a.time_end && typeof a.time_end === 'string' ? a.time_end : a.time;
  };

  /**
   * Time filter
   */
  const inStockTimes = product.times.filter(t => {
    return t.status === 'in-stock' && TimeUtil.parse(pTime(t)).gt(currentTime);
  });

  if (inStockTimes.length === 0) {
    whyFiltered.push('status');
    return whyFiltered;
  }

  const [time1, time2] = times;
  const startTime: TimeLiteral = new TimeUtil(time1);
  const endTime: TimeLiteral = new TimeUtil(time2);
  const availableTimes = inStockTimes.filter(t => {
    const time = new TimeUtil(pTime(t));
    return time.gte(startTime) && time.lte(endTime);
  });

  if (availableTimes.length === 0) {
    whyFiltered.push('times');
  }

  /**
   * Area filter
   */

  const hasArea =
    areas.length > 0
      ? areas.findIndex(id => {
          const hasCityTag =
            product.city_tags?.length > 0 && product.city_tags.indexOf(id) > -1;
          return (
            id === product.city_id ||
            id === product.parent_city_id ||
            hasCityTag
          );
        }) > -1
      : true;

  if (!hasArea) {
    whyFiltered.push('area');
  }

  /**
   * Person filter
   */
  if (person !== 0 && !hasAvailableSeat(availableTimes, person)) {
    whyFiltered.push('person');
  }

  /**
   * Tags filter by category
   */
  if (tags.length > 0) {
    let noMatchTags = false;
    Object.entries(tagsByCategory).forEach(([catId, tagIds]) => {
      const tagIndex = product.tags.findIndex(t => tagIds.indexOf(t.id) > -1);
      if (tagIndex < 0) {
        noMatchTags = true;
      }
    });

    if (noMatchTags) {
      whyFiltered.push('tags');
    }
  }

  return whyFiltered;
};

export type ProductSortFnParam = {
  id: number;
  status: string;
  result: ProductFilterMatchKeys;

  slug?: any;
};

export const productSortMoveAllUnMatchFilterBeforeSoldout = (
  a: ProductSortFnParam,
  b: ProductSortFnParam,
): number => {
  const aStatus = a.status;
  const bStatus = b.status;

  // product  a and b is in-stock
  if (
    aStatus === 'in-stock' &&
    a.result.length === 0 &&
    bStatus === 'in-stock' &&
    b.result.length === 0
  ) {
    return 0;
  }

  // product a is higher when in-stock and product b is soldout or showcase
  if (
    aStatus === 'in-stock' &&
    a.result.length === 0 &&
    (bStatus !== 'in-stock' || b.result.length > 0)
  ) {
    return -1;
  }

  // product B is higher when in-stock and product a is soldout or showcase
  if (
    (aStatus !== 'in-stock' || a.result.length > 0) &&
    bStatus === 'in-stock' &&
    b.result.length === 0
  ) {
    return 1;
  }

  // product a is higher when soldout and product b is showcase
  if (aStatus === 'soldout' && bStatus === 'showcase') {
    return -1;
  }

  // product b is higher when soldout and product a is showcase
  if (aStatus === 'showcase' && bStatus === 'soldout') {
    return 1;
  }

  // product a and b is soldout or showcase
  return 0;
};

export const productSortMoveAllSoldToEnd = (
  a: IProduct,
  b: IProduct,
): number => {
  // product  a and b is in-stock
  if (a.status === 'in-stock' && b.status === 'in-stock') {
    return 0;
  }

  // product a is higher when in-stock and product b is soldout or showcase
  if (a.status === 'in-stock' && b.status !== 'in-stock') {
    return -1;
  }

  // product B is higher when in-stock and product a is soldout or showcase
  if (a.status !== 'in-stock' && b.status === 'in-stock') {
    return 1;
  }

  // product a is higher when soldout and product b is showcase
  if (a.status === 'soldout' && b.status !== 'soldout') {
    return -1;
  }

  // product b is higher when soldout and product a is showcase
  if (a.status !== 'soldout' && b.status === 'soldout') {
    return 1;
  }

  // product a and b is soldout or showcase
  return 0;
};

const sortRank = (a: any, b: any): number => {
  return a === b ? 0 : a > b ? 1 : -1;
};

/**
 * Sort word
 * if word prefix is numbers it will place in end instead of beginning
 * @param a
 * @param b
 */
const alphaNumeric = (a: string, b: string): number => {
  if (a === b) {
    return 0;
  }

  const prefixA = parseInt(a.charAt(0));
  const prefixB = parseInt(b.charAt(0));
  if (!isNaN(prefixA) && !isNaN(prefixB)) {
    return prefixA - prefixB;
  }

  if (!isNaN(prefixA)) {
    return 1;
  }

  if (!isNaN(prefixB)) {
    return -1;
  }

  return a > b ? 1 : -1;
};

/**
 * Sort by date from new to old
 * @param a
 * @param b
 */
const sortByDate = (a, b) => {
  a = new Date(a.replace(' ', 'T'));
  b = new Date(b.replace(' ', 'T'));

  return a === b ? 0 : a > b ? -1 : 1;
};

/**
 * Sort product by filter
 */
export const productSortMatcher = (
  a: IProduct,
  b: IProduct,
  sort: FilterSortTypes,
): number => {
  switch (sort) {
    case '1':
      // by latest created product
      return sortByDate(a.created_at, b.created_at);
    case '2':
      // by latest product menu
      return sortByDate(a.latest_menu_created_at, b.latest_menu_created_at);
    case '3':
      // by product name
      // product with prefix numbers will move at the end
      return alphaNumeric(a.name, b.name);
    case '4':
      // by product sale price
      return sortRank(a.price_sale, b.price_sale);
    case '0':
    default:
      // default sort by weight
      return b.weight - a.weight;
  }
};

// The not matching prioritizing must be sorted like this:
// 1st: Kitchen type = tags
// 2nd: Area = area
// 3rd: Time = times
// 4th: People = person
// 5th: status
const sortRankingByType: Record<ProductFilterTypes | 'none', number> = {
  none: 1,
  tags: 2,
  area: 3,
  times: 4,
  person: 5,
  status: 6,
};

export const soldProductsSortByFilterTypesRank = (
  a: ProductSortFnParam,
  b: ProductSortFnParam,
) => {
  // a and b is mark as filtered
  let aSortRank: keyof typeof sortRankingByType =
    a.result.length === 0
      ? 'none'
      : a.result
          .sort((a, b) => {
            return sortRankingByType[a] - sortRankingByType[b];
          })
          .slice(-1)[0] ?? 'none';

  let bSortRank: keyof typeof sortRankingByType =
    b.result.length === 0
      ? 'none'
      : b.result
          .sort((a, b) => {
            return sortRankingByType[a] - sortRankingByType[b];
          })
          .slice(-1)[0] ?? 'none';

  if (a.slug === 'test-resto') {
    console.log(
      a.slug,
      a.result.sort((a, b) => {
        return sortRankingByType[a] - sortRankingByType[b];
      }),
    );
  }

  if (aSortRank === 'none' && bSortRank === 'none') return 0;

  return sortRankingByType[aSortRank] - sortRankingByType[bSortRank];
};
