import { iniState, ACTION_TYPES } from '../constants';
import { ProductActionTypes } from '../types';

export default (
  state: boolean = iniState.viewInfo,
  action: ProductActionTypes,
): boolean => {
  switch (action.type) {
    case ACTION_TYPES.VIEWING:
      return true;
    case ACTION_TYPES.TOGGLE_VIEW_INFO:
      if (typeof action.payload === 'boolean') {
        return action.payload;
      }
      return !state;
    default:
      return state;
  }
};
