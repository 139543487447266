import { IGiftcardState } from './types';

export const ACTION_TYPES = {
  FORM_FILL_DATA: 'GIFTCARD/FORM_FILL_DATA',
  FORM_SUBMIT: 'GIFTCARD/FORM_SUBMIT',
  PAYMENT: 'GIFTCARD/PAYMENT',
  SET_ACTIVE_STEP_INDEX: 'GIFTCARD/SET_ACTIVE_STEP',
  UPDATE_ORDER: 'GIFTCARD/UPDATE_ORDER',
  COMPLETE_STEP: 'GIFTCARD/COMPLETE_STEP',
};

export const GIFTCARD_STEP1 = 0;
export const GIFTCARD_STEP2 = 1;
export const GIFTCARD_STEP3 = 2;

export const initialState: IGiftcardState = {
  order: {
    order_id: 0,
    payment_url: null,
    line_items: [
      {
        amount: 0,
        title: '',
        type: 'giftcard_total',
        weight: 3,
      },
      {
        amount: 0,
        title: '',
        type: 'tax',
        weight: 2,
      },
      {
        amount: 0,
        title: '',
        type: 'payment_total',
        weight: 1,
      },
    ],
    total: 0,
  },
  loading: false,
  activeStepIndex: 0,
  form_data: {
    0: {
      step: GIFTCARD_STEP1,
      complete: false,
      errors: {},
      data: {
        amount: 0,
        quantity: 1,
      },
    },
    1: {
      step: GIFTCARD_STEP2,
      complete: false,
      errors: {},
      data: {
        name: '',
        email: '',
        email_confirm: '',
        is_subscribe: false,
        is_accept_terms: true,
      },
    },
    2: {
      step: GIFTCARD_STEP3,
      data: {},
      errors: {},
      complete: false,
    },
  },
  steps: {
    0: {
      id: GIFTCARD_STEP1,
      label: 'giftcard.title',
      route: 'step1',
    },
    1: {
      id: GIFTCARD_STEP2,
      label: 'INFO',
      route: 'step2',
    },
    2: {
      id: GIFTCARD_STEP3,
      label: 'PAYMENT',
      route: 'step3',
    },
  },
};
