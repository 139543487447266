import createAction from '../../helpers/createAction';
import {
  createPaginationDefaultState,
  createPaginationSlice,
  IPaginationState,
} from '../shared/paginated';
import { getBlogs, getTags } from '../../../api/earlybird';
import { call, put, select, takeLeading } from 'redux-saga/effects';

export type BlogsStateData = {
  id: number;
  title: string;
  slug: string;
  image: string;
};

export type Tag = {
  id: number;
  name: string;
  slug: string;
  weight: number;
  parent_id: number | null;
  translations: TagTranslations[];
};

export type TagTranslations = {
  tag_id: number;
  name: string;
  slug: string;
  lang: string;
};

export const getTagLocale = (tag: Tag, locale: string = 'da') => {
  const find = tag.translations.find(item => item.lang === locale);
  if (!find) {
    return tag;
  }

  return {
    ...tag,
    name: find.name,
    slug: find.slug,
  };
};

type ExtraData = {
  tags: Tag[] | null;
};
export type IBlogsState = IPaginationState<BlogsStateData, ExtraData>;

export const blogsDefaultState: IBlogsState = createPaginationDefaultState<BlogsStateData>(
  {
    extra: {
      tags: null,
    },
    results: {
      all: {
        loading: false,
        per_page: 9,
        current_page: 1,
        next_page: 1,
        params: {},
      },
    },
  },
);

const SET_ALL_TAGS = 'blogs/setAllTags';
const INITTAGS = 'blogs/initTags';
const extraActions = {
  initTags: createAction(INITTAGS),
  setAllTags: createAction(SET_ALL_TAGS, (tags: any[]) => ({
    payload: tags,
    meta: { type: 'blogs' },
  })),
};

const extraReducer = {
  [extraActions.setAllTags]: function (
    state,
    { payload }: ReturnType<typeof extraActions.setAllTags>,
  ) {
    state.extra.tags = payload;
  },
};

const blogSlice = createPaginationSlice<BlogsStateData>(
  'blogs',
  blogsDefaultState,
  getBlogs,
  extraActions,
  extraReducer,
  function (actions) {
    function* taskFetchTags(
      action: ReturnType<typeof actions.nextPageRequest>,
    ) {
      try {
        const state = yield select(state => state['blogs']);
        if (state.extra.tags) {
          return;
        }

        const response = yield call(getTags);
        yield put(extraActions.setAllTags(response.data));
      } catch (e) {
        console.warn(e);
      }
    }

    return [takeLeading(extraActions.initTags, taskFetchTags)];
  },
);

const actions = blogSlice.actions;
const sagas = blogSlice.sagas;
const reducers = {
  blogs: blogSlice.reducers,
};

export { reducers, actions, sagas };
