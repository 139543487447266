import {
  ActiveProductType,
  IChangeProductType,
  ProductActionTypes,
  ProductType,
  ProductTypes,
} from '../types';
import { CHANGE_PRODUCT_TYPE, iniState } from '../constants';
import { AppState } from '../../../reducers';
import { createSelector } from 'reselect';
import { _get } from '../../../../utils/object-prop';
import { IFilterOption } from '../../shared/types';
import { selectTranslator, TransFn } from '../../languages/trans';
import { IArea } from '../../filters/types';

export const activeProductType = (
  state: ActiveProductType = iniState.activeProductType,
  action: IChangeProductType,
): ActiveProductType => {
  switch (action.type) {
    case CHANGE_PRODUCT_TYPE:
      return action.payload;
    default:
      return state;
  }
};

export const productTypes = (
  state: ProductTypes = iniState.productTypes,
  action: ProductActionTypes,
): ProductTypes => {
  return state;
};

export const selectProductTypes = (state: AppState) =>
  state.products.productTypes;
export const selectLocal = (state: AppState) => state.local;
export const selectActiveParentCity = createSelector(
  [
    (state: AppState) => state.parentAreas,
    (state: AppState) => state.activeParentCityId,
  ],
  (parentAreas, activeParentCityId): IArea => {
    const find = parentAreas.find(p => p.id === activeParentCityId);
    return find ? find : null;
  },
);

export const selectActiveParentCitySlug = createSelector(
  [
    (state: AppState) => state.parentAreas,
    (state: AppState) => state.activeParentCityId,
  ],
  (parentAreas, activeParentCityId): string => {
    const find = parentAreas.find(p => p.id === activeParentCityId);
    return find ? _get(find, 'slug') : 'kobenhavn';
  },
);
export const transformProductType = (
  type: ProductType,
  local: string,
  city: string,
  trans: TransFn,
) => {
  const icons = {
    bar: 'eb eb-bars',
    restaurant: 'eb eb-restaurants',
    takeaway: 'eb eb-takeaway1',
  };
  return {
    ...type,
    route: trans(`routes.${type.id}_by_city`, { city }),
    icon: icons[type.id],
  };
};

export const selectProductTypeFilterOption = createSelector(
  [selectProductTypes, selectLocal, selectActiveParentCity, selectTranslator],
  (productTypes: ProductTypes, local, city, trans): IFilterOption[] => {
    if (!city || !productTypes) {
      return [];
    }
    return Object.entries(productTypes)
      .map(([_, p]) => {
        if (p.supported_city.indexOf(city.id) > -1) {
          return transformProductType(p, local, city.link, trans);
        }

        return null;
      })
      .filter(Boolean);
  },
);

export const selectActiveProductType = createSelector(
  [
    (state: AppState) => state.products.activeProductType,
    selectProductTypes,
    selectLocal,
    selectActiveParentCitySlug,
    selectTranslator,
  ],
  (active, productTypes, local, citySlug, trans) => {
    return transformProductType(productTypes[active], local, citySlug, trans);
  },
);
