import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  BookingStep1,
  IBookingMenu,
  IBookingSeatList,
  IBookingTimeListState,
} from '../types';
import actions from '../actions';
import { selectActiveProduct } from '../../products/reducers/products';
import {
  bookingCalculate,
  bookingHold,
  getProductMenus,
  getProductStockInfos,
  getProductTimes,
} from '../../../../api/earlybird';
import { AppState } from '../../../reducers';
import { selectBookingStepData } from '../reducers';
import { _get } from '../../../../utils/object-prop';
import { BOOKING_STEP1 } from '../constants';
import { validateMenuRecords } from '../validations';
import { selectTranslator } from '../../languages/trans';
import { TimeUtil } from '../../../../utils/date';
import { actions as productActions } from '../../products';
import { actions as sharedActions } from '../../shared';
import {
  IProduct,
  IProductAvailableTime,
  IProductInfoMenu,
} from '../../products/types';
import { validateSteps } from './index';
import { normalize } from 'normalizr';
import * as schemas from '../../../../api/schema';
import { bookingHoldIdFromCookie } from 'Features/Booking/hooks';

const selectBookingHoldId = (state: AppState) => state.booking.order.hold_id;

function* taskFetchAvailableTimeList(
  action: ReturnType<typeof actions.selectNumberOfPerson>,
) {
  if (action.payload === 0) {
    // no need load time list when selected person 0
    yield put(
      actions.selectTime({
        time: '0',
      }),
    );
    return;
  }

  yield put(actions.loading(true));

  try {
    let holdId = yield select(selectBookingHoldId);
    const product = yield select(selectActiveProduct);
    const timeList: IBookingTimeListState = yield call(
      getProductTimes,
      product.id,
      {
        hold_id: holdId,
        seat: action.payload,
      },
    );
    yield put(actions.updateAvailableTimeList(timeList));

    // when the last selected time is not available remove selection
    const step = yield select(selectBookingStepData, 0);
    const timeData: IProductAvailableTime | null =
      step.data.time !== '0'
        ? _get(timeList.entities.times, step.data.time, null)
        : null;

    if (timeData && timeData.status !== 'in-stock') {
      yield put(
        actions.selectTime({
          time: '0',
        }),
      );
    }
  } catch (e) {
    yield put(
      sharedActions.notifyAlert(
        'Failed to load times, Please reload and try again',
      ),
    );

    // clear last time list
    yield put(
      actions.updateAvailableTimeList({
        entities: {},
        result: [],
      }),
    );

    console.error(e);
  }

  yield put(actions.loading(false));
}

/**
 * Automatic selection of person when the menu is only one
 */
function* taskAutoSelectMenuNumPerson() {
  // const product = yield select(selectActiveProduct);
  const step = yield select(selectBookingStepData, 0);
  const menus = yield select(
    (state: AppState) => state.booking.menus?.entities?.menus,
  );

  // const menus = yield select((state: AppState) => state.booking.menus)
  let inStockMenus = 0;
  let inStockMenuId = null;

  // count all in-stock menu and
  // store last in stock menu that later will be use for checking if there's only one available menu

  Object.entries<IProductInfoMenu>(menus).forEach(([_, menu]) => {
    if (menu.status === 'in-stock') {
      inStockMenus++;
      inStockMenuId = menu.id;
    }
  });

  // auto select total num_person when only menu is available
  if (inStockMenuId && inStockMenus === 1) {
    yield put(
      actions.selectProductMenu({
        menu_id: inStockMenuId,
        num_person: step.data.num_person,
      }),
    );
  }
}

function* validateMenu(step: BookingStep1) {
  const trans = yield select(selectTranslator);
  const validate = yield call(validateMenuRecords, step);
  if (!validate) {
    return trans('errors.menus_num_person', {
      num_person: step.data.num_person,
    });
  }

  return true;
}

/**
 * Auto hold booking for 10 minutes when step 1 is fields are fill
 */
function* taskCalculatePayment(step: BookingStep1, shouldHold = true) {
  const errors: Record<string, string> = {};

  // validate time and num person
  if (!(step.data.time !== '0' && step.data.num_person > 0)) {
    yield put(actions.setStepErrors(0, {})); // remove errors
    if (step.complete) {
      yield put(actions.completeStep(0, false));
    }

    return false; // no need to validate empty fields
  }

  const product: IProduct = yield select(selectActiveProduct);
  const lang: string = yield select((state: AppState) => state.local);
  const validate = yield validateMenu(step);
  const trans = yield select(selectTranslator);
  // validate selected items
  if (validate !== true) {
    errors.menus = ''; //validate;
  } else if (!new TimeUtil(step.data.time).isValid()) {
    errors.time = 'Invalid time';
  } else if (!(step.data.num_person > 0 && step.data.num_person <= 8)) {
    errors.num_person = 'Invalid number of people';
  }

  if (Object.keys(errors).length > 0) {
    yield put(actions.completeStep(0, false));
    yield put(actions.setStepErrors(0, errors));
    return false;
  } else {
    try {
      const holdId = yield select(
        (state: AppState) => state.booking.order.hold_id,
      );
      const params = {
        seat: step.data.num_person,
        time: step.data.time,
        time_end: step.data.time_end,
        menus: Object.values<IBookingMenu>(step.data.menus)
          .filter(menu => menu.num_person > 0)
          .map(menu => ({
            id: menu.menu_id,
            count: menu.num_person,
          })),
        lang,
        hold_id: holdId,
      };

      let resultHold;
      if (shouldHold) {
        resultHold = yield call(bookingHold, product.id, params);
      } else {
        resultHold = yield call(bookingCalculate, product.id, params);
      }

      yield put(actions.orderHold(resultHold));
    } catch (e) {
      yield put(sharedActions.notifyAlert(trans('message.all_hold_warning')));
      console.error(e);
      return false;
    }
  }

  return true;
}

function* taskFetchAvailableProductMenuList({
  payload,
}: ReturnType<typeof actions.selectTime>) {
  yield put(actions.loading(true));

  const product = yield select(selectActiveProduct);
  if (!product) {
    return;
  }

  const step = yield select(selectBookingStepData, 0);
  if (!step) {
    return;
  }

  try {
    let holdId = yield select(selectBookingHoldId);
    const local = yield select((state: AppState) => state.local);
    const results = yield call(getProductMenus, product.id, {
      time: payload.time,
      time_end: payload.time_end,
      seat: step.data.num_person,
      local,
      hold_id: holdId,
    });
    yield put(productActions.updateProductInfoMenus(results));
    yield put(productActions.updateProductMenus(results.entities));
    yield put(actions.updateAvailableMenuList(results));
    yield put(actions.loading(false));

    yield taskAutoSelectMenuNumPerson();

    // yield put(actions.menuListUpdate(results))
    // yield taskAutoSelectMenuNumPerson()
  } catch (e) {
    yield put(
      sharedActions.notifyAlert(
        'Failed to load menus, Please reload and try again',
      ),
    );
    console.error(e);
  }

  yield put(actions.loading(false));
}

function* taskFormSubmit(action: ReturnType<typeof actions.formSubmit>) {
  const { step: stepNum } = action.payload;
  if (stepNum !== BOOKING_STEP1) {
    return;
  }

  const { isComplete, errors } = yield call(validateSteps, action);
  const trans = yield select(selectTranslator);
  const stepData = yield select((state: AppState) =>
    selectBookingStepData(state, BOOKING_STEP1),
  );

  yield put(actions.loading(true));

  if (!isComplete) {
    // alert menu error
    if (errors.hasOwnProperty('menus')) {
      yield put(
        sharedActions.notifyAlert(
          trans('errors.menu_select_person', {
            num_person: stepData.data.num_person,
          }),
        ),
      );
    }

    if (errors.hasOwnProperty('menu_table_size_limit')) {
      yield put(
        sharedActions.notifyAlert(
          trans('errors.menu_table_size_limit', {
            menu_name: errors.menu_table_size_limit,
          }),
        ),
      );
    }

    yield put(actions.loading(false));

    return;
  }

  const isSuccess = yield taskCalculatePayment(stepData);

  yield put(actions.loading(false));

  if (isSuccess) {
    yield put(actions.completeStep(0, true));
    yield put(actions.moveToNextStep());
  }
}

function* taskFetchAvailableSeats({
  payload: step,
}: ReturnType<typeof actions.setActiveStepIndex>) {
  if (step !== BOOKING_STEP1) {
    return;
  }

  // const loading = yield select((state: AppState) => state.booking.loading)
  // if (loading) {
  //   return
  // }

  yield put(actions.loading(true));

  try {
    const holdIdFromCookie = bookingHoldIdFromCookie();
    const product = yield select(selectActiveProduct);
    let holdId = yield select(selectBookingHoldId);

    if (!holdId && holdIdFromCookie) {
      holdId = holdIdFromCookie;
    }

    const params = {};
    if (holdId && holdId > 0) {
      params['hold_id'] = holdId;
    }
    const stocks = yield call(getProductStockInfos, product.id, params);
    yield put(productActions.addProductInfo(stocks));
    if (stocks.result) {
      const seats: IBookingSeatList = normalize(
        stocks.entities.products[stocks.result].seats,
        schemas.seatList,
      );
      yield put(actions.updateAvailableSeats(seats));
    }
  } catch (e) {
    yield put(
      sharedActions.notifyAlert(
        'Failed to load menus, Please reload and try again',
      ),
    );
    console.error(e);
  }

  yield put(actions.loading(false));
}

function* taskCalculatePaymentOnSelectMenu() {
  yield put(actions.loading(true));

  const stepData = yield select((state: AppState) =>
    selectBookingStepData(state, BOOKING_STEP1),
  );
  yield taskCalculatePayment(stepData, false);
  yield put(actions.loading(false));
}

export default [
  takeLeading(actions.setActiveStepIndex.type, taskFetchAvailableSeats),
  takeLatest(actions.selectNumberOfPerson.type, taskFetchAvailableTimeList),
  takeLatest(actions.selectTime.type, taskFetchAvailableProductMenuList),
  takeLatest(actions.formSubmit.type, taskFormSubmit),
  takeLatest(actions.selectProductMenu.type, taskCalculatePaymentOnSelectMenu),
];
