export default {
  routes: {
    // product page
    restaurant: '/restaurant/:slug',
    bar: '/bar/:slug',

    // product list by city
    bar_by_city: '/:city/barer',
    restaurant_by_city: '/:city/restauranger',

    // product map by city
    bar_by_city_map: '/kort/:city/barer',
    restaurant_by_city_map: '/kort/:city/restauranger',

    takeaway: '/takeaway/:slug',
    takeaway_by_city_map: '/kort/:city/takeaway',
    takeaway_by_city: '/:city/takeaway',

    // product checkout
    book: '/book/:step/:productType/:slug',

    giftcard: '/presentkort/:step',
  },
  people: 'Personer',
  persons: 'Personer',
  person: 'Person',
  people_not_possible: 'INTE MÖJLIGT',
  time_not_possible: 'INTE MÖJLIG',
  all_people: 'Alla Personer',
  select_people: 'Välj antal personer',
  choose_people: 'Välj antal personer',
  time_suffix: 'IDAG',
  select_time: 'Välj ankomsttid',
  choose_time: 'Ankomsttid',
  choose_time_restaurant: 'Ankomsttid',
  choose_time_bar: 'Ankomsttid',
  choose_time_takeaway: 'Upphämtningstid',
  choose_menu: 'Välj :num menyer',
  view_menu: 'Visa menyer',
  order: 'BESTÄLLNING',
  next: 'Nästa',
  cancel: 'Avbryt',
  accept: 'Acceptera',
  total_filtered: ':productType som matchar din filtrering',
  total_filtered_mobile: [
    'Dagens restauranger som matchar din filtrering:',
    '',
  ],
  giftcard_item: ':quantity PRESENTKORT :price',
  giftcard: {
    title: 'presentkort',
    amount: 'Skriv värdet på presentkortet',
    quantity: 'Antal',
    name: 'Ditt namn',
    email: 'Din e-postadress',
    email_confirm: 'Vänligen ange din e-postadress igen',
    accept_terms: 'Jag accepterar',
    accept_term_link: 'villkoren',
  },
  overlay_title_showcase: 'INTE AKTIV IDAG',
  overlay_title_soldout: 'SLUTSÅLD IDAG',
  overlay_subtitle: 'LÅT DIG FRESTAS AV EN ANNAN :productType',
  errors: {
    menu_table_size_limit:
      ':menu_name kan endast beställas av 2, 4 eller 6 personer',
    'modal-title': ' ',
    menus_num_person: 'Välj menyer för :num_person personer',
    menu_select_person: 'Vänligen välj :num_person menyer innan du fortsätter',
    time: 'Vänligen välj en ankomsttid för din bokning',
    num_person: 'Vänligen välj en ankomsttid för din bokning',
    menus: 'Vänligen välj de menyer du önskar.',
    name: 'Vänligen ange namn',
    email: 'Vänligen ange din e-post',
    phone: 'Vänligen ange ditt mobilnummer',
    'gc-mail-phone':
      'Vänligen ange din e-post och mobilnummer innan du använder koden',
    'gc-exist': 'Presentkortet är redan tillagt',
    'gc-invalid': 'Ogiltigt presentkort',
    'error-gc-expired':
      'Kodens utgångsdatum har passerat och är därför inte längre aktiv.',
    'error-matas-card': 'Ogiltigt ClubMatas nummer',
    'error-gc-status': 'Koden är använd och därför inte giltig',
    'error-gc-user': 'Koden kan inte användas på denna plats.',
    'error-gc-newuser':
      'Koden gäller endast nya kunder som handlar för första gången på earlybird.dk',
    'error-gc-maxuse':
      'Du har tidigare använt rabattkoden och kan inte använda den igen.',
    'error-gc-resto': 'Koden fungerar inte på denna plats',
    giftcard_amount: 'Ange det belopp du vill ska stå på presentkortet.',
    email_confirm: 'E-postadresserna stämmer inte överens',
    accept_terms: 'Du måste acceptera villkoren',
    'bycode-giftcard_validate_cover':
      'Koden är endast giltig för :cover personer',
    'bycode-giftcard_validate_user':
      'Koden är tyvärr endast giltig för nya kunder',
    'bycode-giftcard_validate_status': 'Presentkortet är redan använt',
    'bycode-giftcard_validate_expired': 'Tyvärr är koden utgången',
    'bycode-giftcard_validate_resto':
      'Presentkoden gäller tyvärr inte för denna plats',
    'bycode-giftcard_validate_maxuse':
      'Du har redan använt koden och kan inte använda den igen',
    no_table_available:
      'Tyvärr, det sista bordet är just sålt, så du kan inte slutföra köpet',
    coop_code: 'OGILTIGT MEDLEMSNUMMER',
    coop_zip: 'Ogiltigt postnummer',
  },
  line_item: {
    menus: 'MENYER',
    tax: 'MOMS UTGÖR 25%',
    admin_fee: 'ADMINISTRATIV AVGIFT',
    payment_total: 'TOTALT BELOPP',
    giftcode: 'PRESENTKORTSKOD',
    giftcard_item: ':quantity PRESENTKORT :price',
    giftcard_total: 'TOTALT',
    coop: 'COOP MEDLEMS NR',
    zip_code: 'Postnummer',
  },
  buttons: {
    ok: 'OK',
    cancel: 'AVBRYT',
    yes: 'Ja',
    no: 'Nej',
  },
  remove_gcode_confirm: 'Vill du ta bort koden?',
  filter_reasons: {
    times: 'Tidpunkt',
    area: 'Områden',
    person: 'Person',
    tags: 'Kök & Köksstil',
  },
  book_table: 'BOKA BORD',
  book_table_bar: 'BOKA',
  book_table_restaurant: 'BOKA BORD',
  book_table_takeaway: 'BESTÄLL',
  book_table_sold: 'SLUTSÅLD',
  product_page: {
    the_restaurant: 'OM RESTAURANG',
    menu: 'MENY',
  },
  cut_price: 'DU FÅR ALLTID 1/3 I PRISAVDRAG MED TABLE TODAY',
  arrival_time_restaurant: 'Ankomsttid',
  arrival_time_bar: 'Ankomsttid',
  arrival_time_takeaway: 'Upphämtningstid',
  filter_area: 'Områden',
  times_of_today: 'TIDER IDAG',
  times_of_today_bar: 'TIDER IDAG',
  times_of_today_restaurant: 'TIDER IDAG',
  times_of_today_takeaway: 'UPPHÄMTNINGSTIDER IDAG',
  show_menus: 'VISA MENY',
  the_resto: 'Om Restaurang',
  the_bar: 'Om Baren',
  gallery: 'Galleri',
  reset_filter: 'ÅTERSTÄLL FILTER',
  save_search: 'VISA :total RESULTAT',
  viewtype_list: 'lista',
  viewtype_map: 'Karta',
  address: 'Hitta vägen',
  use: 'ANVÄND',
  fullname: 'NAMN',
  emailfield: 'E-POST',
  phonenum: 'MOBILNUMMER',
  giftcode: 'PRESENTKORT & RABATTKODER',
  subscribe: 'Anmäl mig till nyhetsbreven med nya spännande restauranger.',
  map_address: 'Adress',
  selected: 'vald',
  see_product: 'SE DAGENS :product',
  BOOK: 'BOKA',
  INFO: 'INFO',
  TERMS: 'VILLKOR',
  PAYMENT: 'BETALNING',
  order_giftcard: 'Köp av presentkort',
  filter_matcher: {
    doesnt: 'SÖKNING',
    search_on: 'MATCHAR INTE PÅ',
  },
  choose: 'VÄLJ',
  see_todays: 'Se dagens',
  see_todays_in: 'i',
  more_menu: 'andra menyer',
  filter_unselectall: 'AVMARKERA ALLA',
  filter_save: 'SPARA',
  payment_failed_error_msg: 'Betalningen misslyckades. Försök igen.',
  how_many_are_you: 'Hur många är ni?',
  select: 'Välj',
  gc_success_msg: ':code har nu dragits av från betalningspriset',
  '2 people': '2 personer',
  '3 people': '3 personer',
  '4 people': '4 personer',
  '5 people': '5 personer',
  '6 people': '6 personer',
  '7 people': '7 personer',
  '8 people': '8 personer',
  change_parent_city: 'Byt till :city',
  view_on_map: 'Visa på karta',

  underserving_tag:
    'Uteservering är möjlig men kan inte reserveras.\n Fråga i restaurangen vid ankomst.',
  menu_select_entire_table: 'Denna meny måste väljas av hela bordet.',

  menu_type_text: {
    lunch: 'Menyn serveras endast till lunch.',
    dinner: 'Menyn serveras endast på kvällen.',
  },
  no_times: 'Inga tillgängliga tider',
  read_more: 'Läs mer',
  show_all_resto: 'Visa alla restauranger',
  search: 'Sök',
  remaining_gc_notice:
    'Ett presentkort på resterande :value kr skickas till :email när beställningen är slutförd.',
  message: {
    all_hold_warning:
      'Just nu håller någon annan på att köpa den sista platsen,\n och därför är det inte möjligt att fortsätta denna beställning.',
  },
  back: 'Tillbaka',
  all: 'Alla',
  zip_code: 'Postnummer',
  coop_mem_no: 'Coop medlemsnummer',
  coop_member_registered: 'DITT MEDLEMSNUMMER ÄR REGISTRERAT',
  sweden_vat: 'Priserna är i SEK och inkl. moms.',
  menu: 'Meny',
};
