import { ConnectedRouter } from 'connected-react-router';
import { actions } from 'core/redux/features/shared';
import initStore, { history } from 'core/redux/stores';
import BottomNavBar from 'Features/Products/BottomNavBar';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import { Portal } from 'react-portal';

import { Provider } from 'react-redux';
import {
  // useDataLayerPurchase,
  useGCDataLayerPurchase,
  useSubscribeDataLayer,
} from 'utils/dataLayer';
import {
  useSnapChatPageView,
  useSnapChatTrackPurchase,
} from 'utils/snapchatAds';
import { debounce } from './core/utils';
import Header from './Features/Header';
import Notification from './Features/Notification/loadable';
import PageBoxes from './Features/PageBoxes';
import preloadState, { getMediaQuery } from './preloadstate';
import RouteList from './routes';

export const stores = initStore(preloadState());

const updateDeviceType = (type: string) => {
  if (stores.getState().deviceType === type) return;
  stores.dispatch(actions.changeDeviceType(type));
};

// set device type
getMediaQuery(updateDeviceType);

// update device type when resize with debounce
window.addEventListener(
  'resize',
  debounce(() => {
    console.log('resize');
    getMediaQuery(updateDeviceType);
  }, 200),
);

export const AppNav = () => {
  const $node = document.getElementById('eb-nav-root');
  if (!$node) {
    return null;
  }
  return (
    <Portal node={$node}>
      <Notification />
      <Header menuId="main" />
    </Portal>
  );
};

export const AppPageBox = () => {
  const $node = document.getElementById('eb-boxb-root');

  if (!$node) {
    return null;
  }
  return (
    <Portal node={$node}>
      <PageBoxes />
    </Portal>
  );
};

function AppHookInit() {
  useSnapChatPageView();
  useSnapChatTrackPurchase();
  // useDataLayerPurchase();
  useSubscribeDataLayer();
  useGCDataLayerPurchase();

  return null;
}

// export default
export default function App() {
  return (
    <Provider store={stores}>
      <ConnectedRouter history={history}>
        <CookiesProvider>
          <AppHookInit />
          <AppNav />
          <RouteList />
          <AppPageBox />
        </CookiesProvider>
      </ConnectedRouter>
    </Provider>
  );
}
