let timeout: any;

export default <F extends Function>(fn: F, delay = 100) => {
  return (...args: any) => {
    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};
