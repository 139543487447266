import { INotificationsState, IViewTypeState } from './types';

export const defaultViewTypes = {
  list: {
    id: 'list',
    label: 'viewtype_list',
    icon: 'eb eb-list',
    routeTransKey: '',
    slugs: {
      en: '',
      da: '',
    },
  },
  map: {
    id: 'map',
    label: 'viewtype_map',
    icon: 'eb eb-map1',
    routeTransKey: '',
    slugs: {
      en: 'map',
      da: 'kort',
    },
  },
};

export const defaultViewState: IViewTypeState = {
  entities: {
    viewTypes: defaultViewTypes,
  },
  activeViewType: defaultViewTypes.list.id,
};

export const defaultNotificationState: INotificationsState = {
  body: '',
  id: 'error-modal',
  onCloseCallback: undefined,
  buttons: [],
  message: '',
  open: false,
  title: '',
};
