import { AppState } from '../../reducers';
import { createSelector } from 'reselect';
import { _get } from '../../../utils/object-prop';
import createReducer from '../../helpers/createReducer';

export type BoxId = string;

export interface IBoxState {
  entities: IBoxRecords;
  ids: BoxId[];
}

export type IBoxRecords = Record<string, IBox>;

export interface IBox {
  id: BoxId;
  content: string;
}

export const boxInitialState: IBoxState = {
  entities: {},
  ids: [],
};

export const reducers = createReducer(boxInitialState);

export const makeSelectBox = () =>
  createSelector(
    [
      (state: AppState) => state.box.entities,
      (state: AppState, id: BoxId) => id,
    ],
    (boxes: IBoxRecords, id: BoxId): IBox | null => {
      return _get(boxes, id, null);
    },
  );

export const selectBox = createSelector(
  [(state: AppState) => state.box.entities, (state: AppState, id: BoxId) => id],
  (boxes: IBoxRecords, id: BoxId): IBox | null => {
    return _get(boxes, id, null);
  },
);
