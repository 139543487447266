import { createSelector } from 'reselect';
import { AppState } from '../../reducers';
import { selectTranslator } from '../languages/trans';
import { ProductType } from '../products/types';
import createAction from '../../helpers/createAction';
import createReducer from '../../helpers/createReducer';
import { IArea } from '../filters/types';

export const ACTION_TYPES = {
  OPEN: 'nav/open',
  CLOSE: 'nav/close',
  TOGGLE: 'nav/toggle',
  MENU_HIDDEN: 'nav/menuHidden',
  SET_OPTION: 'nav/setOption',
  STATUS_UPDATED: 'nav/statusUpdated',
};

export enum NavTypes {
  Menu,
  Filter,
}

export interface INavMenuSettings {
  nofollow: string;
  icon: string;
  hide_in_app: 'hide_in_app';
  target: string | null;
}

export type NavMenuType = 'menu_item' | 'menu_label';

export interface INavMenu {
  title: string;
  link: string;
  menu_id: string;
  order: number;
  id: number;
  settings: Partial<INavMenuSettings>;
  product_type?: string;
  type: NavMenuType;
}

export enum NavMenuTypes {
  main = 'main',
  local = 'local',
  social = 'social',
}

export type NavActionTypes =
  | ActionOpenNav
  | ActionCloseNav
  | ActionToggleNav
  | ActionSetNavOption;

export interface ActionOpenNav {
  type: typeof ACTION_TYPES.OPEN;
  payload?: NavActionTypes;
}

export interface ActionCloseNav {
  type: typeof ACTION_TYPES.CLOSE;
  payload?: NavActionTypes;
}

export interface ActionToggleNav {
  type: typeof ACTION_TYPES.TOGGLE;
  payload?: NavActionTypes;
}

export interface ActionSetNavOption {
  type: typeof ACTION_TYPES.SET_OPTION;
  payload: NavTypes;
}

export enum MenuStatus {
  pin = 'pin',
  unpin = 'unpin',
  unfixed = 'unfixed',
  hidden = 'hidden',
}
export type MenuId = string;

const _actionPayloadCreator = () => (type: NavTypes) => ({
  payload: { type },
});

export const actions = {
  /**
   * Open navigation
   * @param {NavTypes} type
   * @param {string} id
   */
  open: createAction(ACTION_TYPES.OPEN, _actionPayloadCreator()),
  /**
   * Close navigation
   * @param {NavTypes} type
   * @param {string} id
   */
  close: createAction(ACTION_TYPES.CLOSE, _actionPayloadCreator()),
  /**
   * Toggle navigation
   * @param {NavTypes} type
   * @param {string} id
   */
  toggle: createAction(ACTION_TYPES.TOGGLE, _actionPayloadCreator()),
  /**
   * Toggle navigation
   * @param {string} menuId
   * @param {boolean} isHide
   */
  hideMenu: createAction(
    ACTION_TYPES.MENU_HIDDEN,
    function (menuId: MenuId, isHide: boolean) {
      return {
        payload: { menuId, isHide },
      };
    },
  ),
  /**
   * Update menu status by id
   * @package {MenuId} menuId
   * @param {MenuStatus} status
   */
  updateStatus: createAction(
    ACTION_TYPES.STATUS_UPDATED,
    function (menuId: MenuId, status: MenuStatus) {
      return {
        payload: { menuId, status },
      };
    },
  ),
};

export interface INavState {
  active: boolean;
  type: NavTypes;
  hideById: Record<string, boolean>;
  menus: {
    main: INavMenu[];
    social: INavMenu[];
  };
  statusById: Record<string, MenuStatus>;
}

export const navInitialState: INavState = {
  menus: {
    main: [],
    social: [],
  },
  active: false,
  type: NavTypes.Menu,
  hideById: {
    backbtn: true,
    // 'all': false,
  },
  statusById: {},
};

export const reducers = createReducer<INavState>(navInitialState, {
  [actions.open]: (state, { payload }: ReturnType<typeof actions.open>) => {
    state.type = payload.type;
    state.active = true;
    return state;
  },
  [actions.close]: (state, { payload }: ReturnType<typeof actions.close>) => {
    state.type = payload.type;
    state.active = false;
    return state;
  },
  [actions.toggle]: (state, { payload }: ReturnType<typeof actions.toggle>) => {
    state.type = payload.type;
    state.active = !state.active;
    return state;
  },
  [actions.hideMenu]: (
    state,
    { payload }: ReturnType<typeof actions.hideMenu>,
  ) => {
    if (state.hideById[payload.menuId] !== payload.isHide) {
      state.hideById[payload.menuId] = payload.isHide;
    }

    return state;
  },
  [actions.updateStatus]: (
    state,
    { payload }: ReturnType<typeof actions.updateStatus>,
  ) => {
    state.statusById[payload.menuId] = payload.status;
    return state;
  },
});

export const makeSelectNavIsActive = () => {
  return createSelector(
    [
      (state: AppState) => state.nav.active,
      (state: AppState) => state.nav.type,
      (_: AppState, type: NavTypes) => type,
    ],
    function (
      isActive: boolean,
      activeType: NavTypes,
      type: NavTypes,
    ): boolean {
      if (activeType === type) {
        return isActive;
      }

      return false;
    },
  );
};

export const makeSelectNavIsHidden = () => {
  return createSelector(
    [
      (state: AppState) => state.nav.hideById,
      (_: AppState, menuId: string) => menuId,
    ],
    function (byId, menuId) {
      return byId[menuId] ?? false;
    },
  );
};

export const makeSelectNavMenu = () => {
  return createSelector(
    [
      (state: AppState) => Object.values(state.products.productTypes),
      (state: AppState) => state.parentAreas,
      (state: AppState) => state.nav.menus,
      (_: AppState, type: NavMenuTypes) => type,
      selectTranslator,
    ],
    (
      productTypes: ProductType[],
      cities: IArea[],
      menus,
      type: NavMenuTypes,
      trans,
    ): INavMenu[] => {
      let navMenus: INavMenu[] = [];
      // return nav type from state
      if (menus.hasOwnProperty(type)) {
        navMenus = menus[type];
      } else if (type === NavMenuTypes.local) {
        // create nav menu list base on product types and cities

        let navMenuId = 0;
        productTypes.forEach(productType => {
          cities.forEach(city => {
            navMenuId++;

            // if (
            //   productType.id === 'bar' &&
            //   city.slug.toLowerCase() === 'aarhus'
            // ) {
            // } else {
            navMenus.push({
              id: navMenuId,
              order: navMenuId,
              menu_id: NavMenuTypes.local,
              settings: {},
              type: 'menu_item',
              title: `${trans('see_todays')} ${productType.label} ${trans(
                'see_todays_in',
              )} <strong>${city?.label}</strong>`,
              link: trans(`routes.${productType.id}_by_city`, {
                city: city.slug,
              }),
            });
            // }
          });
        });
      }

      return navMenus;
    },
  );
};

export const selectNavStatus = createSelector(
  [
    (state: AppState) => state.nav.statusById,
    (state: AppState, menuId: MenuId) => menuId,
  ],
  (menus, menuId) => {
    return menus[menuId] ?? MenuStatus.pin;
  },
);
