import {
  createPaginationDefaultState,
  createPaginationSlice,
  IPaginationState,
} from '../shared/paginated';
import { getPageBoxes } from '../../../api/earlybird';

export type PageBoxesStateData = {
  id: number;
  title: string;
  content: string;
  text_type: string;
  code: string;
  background_type: string;
  background_image: string;
  background_color: string;
  font_color: string;
  html_class: string;
  enable_block_section: string;
  background_alt: string;
  hide_app: '0' | '1';
  fixed_width: 1 | 0;
};

export type IPageBoxesState = IPaginationState<PageBoxesStateData>;

export const pageBoxesDefaultState: IPageBoxesState = createPaginationDefaultState<PageBoxesStateData>(
  {
    defaultGroup: 'bottom',
    results: {
      bottom: {
        loading: false,
        per_page: 5,
        current_page: 0,
        next_page: 1,
        params: {
          position: 'bottom',
        },
        data: [],
      },
      top: {
        loading: false,
        per_page: 5,
        current_page: 0,
        next_page: 1,
        params: {
          position: 'top',
        },
        data: [],
      },
    },
  },
);

const pageBoxSlice = createPaginationSlice<PageBoxesStateData>(
  'pageBoxes',
  pageBoxesDefaultState,
  getPageBoxes,
);

const actions = pageBoxSlice.actions;
const sagas = pageBoxSlice.sagas;
const reducers = {
  pageBoxes: pageBoxSlice.reducers,
};

export { reducers, actions, sagas };
