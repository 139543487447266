import {
  BookingStepId,
  IBookingMenu,
  IBookingMenuListState,
  IBookingOrder,
  IBookingSeatList,
  IBookingTimeListState,
  TBookingTime,
} from './types';
import { ACTION_TYPES } from './constants';
import createAction from '../../helpers/createAction';
import { LoadingType } from '../../../index';

export default {
  moveToNextStep: createAction(ACTION_TYPES.FORM_MOVE_NEXT_STEP),
  loading: createAction<LoadingType>(ACTION_TYPES.LOADING),
  /**
   * Update Number of guest
   * @param {number} numPerson
   */
  selectNumberOfPerson: createAction<number>(
    ACTION_TYPES.NUMBER_OF_PERSON_UPDATED,
  ),
  /**
   * Request available time to api
   * @param {string} time IProductAvailableTime
   */
  selectTime: createAction<TBookingTime>(ACTION_TYPES.TIME_SELECTED),
  /**
   * Update available menu list
   * @param {IProductMenus} timeList
   */
  updateAvailableMenuList: createAction<IBookingMenuListState>(
    ACTION_TYPES.SET_MENUS,
  ),
  /**
   * Guest select product menu action
   * @param {IBookingMenu} productMenu
   */
  selectProductMenu: createAction<IBookingMenu>(
    ACTION_TYPES.PRODUCT_MENU_SELECTED,
  ),
  /**
   * Update available time list
   * @param {IBookingTimeListState} timeList
   */
  updateAvailableTimeList: createAction<IBookingTimeListState>(
    ACTION_TYPES.TIME_LIST_UPDATED,
  ),
  /**
   * Update available seat list
   * @param {IProductSeat[]} seats
   */
  updateAvailableSeats: createAction<IBookingSeatList>(
    ACTION_TYPES.SEAT_LIST_UPDATED,
  ),
  /**
   * Update available seat for
   */
  // setAvailableSeatListForProductMenus: createAction<number[]>(ACTION_TYPES.SET_SEAT_OPTION),

  setStepErrors: createAction(
    ACTION_TYPES.FORM_ERRORS,
    (step: number, errors: Record<string, string>) => ({
      payload: {
        step,
        errors,
      },
    }),
  ),
  completeStep: createAction(
    ACTION_TYPES.FORM_COMPLETE_STEP,
    (step: number, is_complete: boolean) => ({
      payload: {
        step,
        is_complete,
      },
    }),
  ),
  orderHold: createAction<IBookingOrder>(ACTION_TYPES.ORDER_HOLD),
  setActiveStepIndex: createAction<BookingStepId>(
    ACTION_TYPES.SET_ACTIVE_STEP_INDEX,
  ),
  fillStep2Data: createAction(
    ACTION_TYPES.FORM_FILL_STEP2_DATA,
    (step: BookingStepId, data: Record<string, any>) => ({
      payload: {
        step,
        data,
      },
    }),
  ),
  fillForm: createAction(
    ACTION_TYPES.FORM_FILL,
    (step: BookingStepId, data: Record<string, any>) => ({
      payload: {
        step,
        data,
      },
    }),
  ),
  formSubmit: createAction(
    ACTION_TYPES.FORM_SUBMIT,
    (step: BookingStepId, data: Record<string, any> = {}) => {
      return {
        payload: {
          step,
          data,
        },
      };
    },
  ),
  /**
   * Validate giftcode action
   * @param  {string} giftcode
   * @param  {string} email
   * @param  {string} phone
   */
  giftcodeValidateRequest: createAction(
    ACTION_TYPES.GIFTCODE_VALIDATE_REQUESTED,
    (giftcode: string, email: string, phone: string) => ({
      payload: {
        giftcode,
        email,
        phone,
      },
    }),
  ),
  /**
   * Validate coop action
   * @param  {string} giftcode
   * @param  {string} email
   * @param  {string} phone
   */
  coopCodeValidateRequest: createAction(
    ACTION_TYPES.COOP_CODE_VALIDATE_REQUESTED,
    (coopCode: any, coopZip: any) => ({
      payload: {
        coopCode,
        coopZip,
      },
    }),
  ),
  /**
   * Giftcode is valid for use
   * @param {string} code
   */
  giftcodeValidateComplete: createAction(
    ACTION_TYPES.GIFTCODE_VALIDATE_COMPLETED,
    (code: string) => ({
      payload: code,
    }),
  ),
  /**
   * remove gift code
   * @param {string} code
   */
  giftcardRemoveRequest: createAction(
    ACTION_TYPES.GIFTCODE_REMOVE_REQUESTED,
    (code: string) => ({
      payload: code,
    }),
  ),

  // menuListUpdate: createAction<NormalizedSchema<IProductInfoEntity, string>>(
  //   ACTION_TYPES.MENU_LIST_UPDATE
  // ),
  updateHoldId: createAction(
    ACTION_TYPES.BOOKING_HOLD_ID_UPDATED,
    (id: number) => ({
      payload: id,
    }),
  ),
};
